import React, { useState, useEffect } from 'react';
import ReservationCheckout from '../../components/reservation/step-3';
import { Rentvisie } from '@bakkie/ratality';
import { Container } from '@mui/system';
import ReservationHeader from '../../components/reservation/shared/reservation-header';
import SEO from '../../components/seo';

const CheckoutTemplate = (props) => {
  const query = new URLSearchParams(props.location.search);

  const reservationReference = query.get('reservationReference');
  const [reservationData, setReservationData] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(undefined);

  useEffect(() => {
    Rentvisie.setConfig({
      baseUrl: 'https://rentvisie-proxy-aws.herokuapp.com/api',
      client: 'ddv',
      useGuestToken: true,
    });

    Rentvisie.getReservationSecured(reservationReference)
      .then(setReservationData)
      .catch(setError)
      .finally(() => setLoading(false));
  }, []);

  if (loading) {
    return (
      <Container>
        <ReservationHeader activeStepIndex={3} isCar={true} />
        <div className="reservation__title">
          <h2>Gegevens Aanvullen</h2>
        </div>
        <div className="reservation-select-wrapper">
          <div className="reservation-select-left">
            <p>
              Goed nieuws: het voertuig is voor u. Vul uw gegevens in om de
              reservering compleet te maken.
            </p>
            <p>Ogenblik geduld...</p>
          </div>
          {/* <div className="reservation-select-right">
            <Contact />
          </div> */}
        </div>
      </Container>
    );
  }
  if (error) {
    return (
      <p>
        Er is iets mis gegaan. Probeer een nieuwe reservering te maken of bel
        ons.
      </p>
    );
  }
  return (
    <>
      <SEO
        title="Huur uw voertuig bij DDV"
        description="Voordelig en snel geregeld bij DDV Autoverhuur"
        follow={true}
        index={false}
      ></SEO>
      <ReservationCheckout reservationData={reservationData} query={query} />
    </>
  );
};

export default CheckoutTemplate;
