import { Container } from '@mui/material';
import Categories from './shared/cta-form/categories';
import CtaForm from './shared/cta-form/form';

const category = 'bestelwagens';
const props = {};

export const HeroHome = () => (
  <div className="hero__background">
    <Container className="relative">
      <div className="hero__cta">
        <CtaForm props={props}></CtaForm>
      </div>
    </Container>
  </div>
);
