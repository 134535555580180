import React, { useEffect, useState } from 'react';
import { Container } from '@mui/system';
import ReservationHeader from './shared/reservation-header';
import { navigate } from 'gatsby';
import CtaForm from '../shared/cta-form/form';
import { locations } from '../settings.json';
import QuoteForm from '../modals/reservation_quoteForm';
import Settings from '../settings.json';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { RootState } from '../../redux/store';
import VatToggle from '../vatToggle';
import SearchImageCarousel from './product-carousel-search';

const ReservationSelectVehicle = ({ availableRentals, searchArgs }) => {
  const [pushedDataLayer, setPushedDataLayer] = useState(false);
  const dataLayer = window.dataLayer || [];
  const [showForm, setShowForm] = useState(false);
  const [open, setOpen] = useState(false);
  const [show, setShow] = useState(false);
  const includeVat = useSelector((state: RootState) => state.vat.includeVat);
  const dispatch = useDispatch();
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    if (includeVat === 'yes') {
      setChecked(true);
    } else {
      setChecked(false);
    }
  }, [includeVat]);

  if (!pushedDataLayer) {
    dataLayer.push({ ecommerce: null });
    dataLayer.push({
      event: `view_item_list`,
      ecommerce: {
        items: [
          availableRentals.map((item, index, data) => ({
            item_list_name: `Reservering Stap 1 - ${item.vehicleClass.category.description}`,
            item_name: `${item.vehicleClass.description}`,
            item_id: `${item.vehicleClass.vehicleClassId}`,
            item_availability: `${item.available}`,
            price: `${item.price.value}`,
            price_excl_vat: `${parseFloat(
              (item.price.value / 1.21).toFixed(2)
            )}`,
            currency: 'EUR',
            item_category: `${item.vehicleClass.category.categoryId}`,
            list_length: `${data.length}`,
            quantity: 1,
            pick_up_location_id: `${item.pickupLocation.locationId}`,
            pick_up_location: `${item.pickupLocation.town}`,
          })),
        ],
      },
    });
    setPushedDataLayer(true);
  }

  const compare = (a, b) => {
    if (a.price.value < b.price.value) {
      return -1;
    }
    if (a.price.value > b.price.value) {
      return 1;
    }
    return 0;
  };

  let category = searchArgs.categoryId;
  let pickupLocation = searchArgs.pickupLocationId;
  let pickupDate = searchArgs.pickupDate;
  let pickupTime = searchArgs.pickupTime;
  let returnDate = searchArgs.dropOffDate;
  let returnTime = searchArgs.dropOffTime;

  if (availableRentals.length > 0) {
    category = availableRentals[0].vehicleClass.category.categoryId;
    pickupLocation = availableRentals[0].pickupLocation;
    pickupDate = availableRentals[0].pickupDate;
    pickupTime = availableRentals[0].pickupTime;
    returnDate = availableRentals[0].dropOffDate;
    returnTime = availableRentals[0].dropOffTime;
  }

  const parseLocation = {
    value: pickupLocation.locationId,
    label: pickupLocation.town,
  };

  // TODO: Fix
  if (availableRentals.length === 0) {
    parseLocation.value = searchArgs.pickupLocationId;
    {
      locations.map((loc) => {
        if (loc.value === Number(parseLocation.value)) {
          parseLocation.label = loc.label;
        }
      });
    }
  }

  // Check Real Time
  const CheckRealTime = async (availableRental) => {
    const filterCategoryId = await Settings.categories.filter(
      (category) =>
        category.id === availableRental.vehicleClass.category.categoryId
    );

    console.log(availableRental)

    const proceedWithReservation = await filterCategoryId[0].realTime;

    if (proceedWithReservation !== 'no' && availableRental.totalPrice.value >= 1) {
      navigate(`/huren/accessoires-kiezen?id=${availableRental.rentalId}`);
      // proceed with real time reservation
    } else {
      // add modal with quote
      setShow(availableRental.vehicleClass.vehicleClassId);
      setOpen(true);
    }
  };

  return (
    <Container style={{ paddingBottom: 50 }}>
      <VatToggle />
      <ReservationHeader activeStepIndex={1} isCar={true} />
      {showForm && (
        <CtaForm
          category={category}
          location={parseLocation}
          pickupDate={pickupDate}
          pickupTime={pickupTime}
          returnDate={returnDate}
          returnTime={returnTime}
        ></CtaForm>
      )}
      <div className="reservation__title">
        <h2>Beschikbare Voertuigen</h2>
      </div>
      <div
        style={{
          display: 'flex',
          gap: '1rem',
          alignItems: 'center',
          marginBottom: 5,
        }}
      >
        <button onClick={() => setShowForm(!showForm)}>
          {showForm ? 'Zoeken Inklappen' : 'Zoekopdracht aanpassen'}
        </button>
      </div>
      <div className="reservation-select-wrapper">
        <div>
          {availableRentals.length === 0 && (
            <div className="hero-form__warning">
              <div> (!) </div>
              <p>
                Zoekopdracht heeft helaas geen resultaten opgeleverd. Probeer
                het op een ander tijdstip en/of bij een andere vestiging.{' '}
              </p>
              <div></div>
            </div>
          )}
          <div className="cards__grid">
            {availableRentals.sort(compare).map((availableRental) => (
              <>
                <div
                  className="card"
                  key={availableRental.vehicleClass.vehicleClassId}
                >
                  <div className="card__content">
                    <div className="card__title-wrapper">
                      <div className="card__title">
                        <h3>{availableRental.vehicleClass.description}</h3>
                      </div>
                      <div className="card__info-icon">i</div>
                    </div>
                    {/* <div className="card__image"> */}
                      {/* TODO: Add carousel */}
                      <SearchImageCarousel assets={availableRental.vehicleClass.images} isStrapi={false} />
                      {/* <img
                        src={availableRental.vehicleClass.images[0].imageUrl}
                        alt={availableRental.vehicleClass.description}
                        style={{ maxWidth: '100%' }}
                      /> */}
                    {/* </div> */}
                    <div className="card__features" onClick={() => CheckRealTime(availableRental)}>
                      {availableRental.vehicleClass.tags &&
                        availableRental.vehicleClass.tags.map((tag) => (
                          <div className="card__feature" key={tag.tagId}>
                            {tag.tagDescription}
                          </div>
                        ))}
                    </div>
                  </div>
                  <div className="card__price" onClick={() => CheckRealTime(availableRental)} >
                    Totaal:
                    {availableRental.price.value < 1 && 
                        'Online niet beschikbaar'
                    }
                    {availableRental.price.value >= 1 &&
                      (includeVat === 'yes'
                        ? ` € ${' '} ${availableRental.price.value
                            .toFixed(2)
                            .toString()
                            .replace('.', ',')}`
                        : ` € ${' '} ${(availableRental.price.value / 1.21)
                            .toFixed(2)
                            .toString()
                            .replace('.', ',')}`)}
                    {includeVat === 'yes' ? ' incl. BTW' : ' excl. BTW'}
                  </div>
                  
                  {availableRental.price.value < 1 ? <div className="card__more-info-disabled">Online niet beschikbaar</div> : <div className="card__more-info" onClick={() => CheckRealTime(availableRental)} >Selecteer</div>}
                </div>
                {show === availableRental.vehicleClass.vehicleClassId && (
                  <QuoteForm
                    open={open}
                    setOpen={setOpen}
                    requestedVehicle={availableRental.vehicleClass}
                  ></QuoteForm>
                )}
              </>
            ))}
          </div>
        </div>
      </div>
    </Container>
  );
};

export default ReservationSelectVehicle;
