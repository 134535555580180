import * as React from 'react';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import '../styles/styles.scss';
import Navbar from './navigation/Navbar';
import FooterNav from './navigation/Footer';
import CompleteCheckout from './reservation/completeCheckout';
import Customer from './../images/svg/icons8-customer.png';
import BusinessBuilding from './../images/svg/icons8-business-building.png';
import UpArrow from '../images/icons/chevron-up.png';
import VatToggle from './vatToggle';

const Layout = ({ children }) => {
  const [showButton, setShowButton] = useState(false);
  const [standard, setStandard] = useState(false);
  const [askVat, setAskVat] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.pageYOffset > 50) {
        setStandard(true);
      } else {
        setStandard(false);
      }
      if (window.pageYOffset > 300) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    });
  }, []);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const itemStr = localStorage.getItem('includeVat');

      // if the item doesn't exist, return null
      if (!itemStr) {
        setAskVat(true);
      }
    }
  }, []);

  // This function will scroll the window to the top
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // for smoothly scrolling
    });
  };

  return (
    <>
      <Navbar standard={standard} children={children} />
      <VatToggle />

      <main style={{ minHeight: '70vh', width: '100%' }}>{children}</main>
      {/* 
      TODO: FIX COMPLETE CHECKOUT AT THANK YOU PAGE 
      <CompleteCheckout />
      */}
      {showButton && (
        <button onClick={scrollToTop} className="back-to-top">
          <img src={UpArrow} alt="^" width={18} height={18} />
        </button>
      )}

      <FooterNav />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
