import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const RentLocations = () => (
    <div className="rent-locations__wrapper">
        <div className="rent-locations__title">
        <h3 className="bold">Huurlocaties</h3></div>
        <ul>
            <li key="1">
                <span className="rent-locations__icon">
                    <FontAwesomeIcon icon="map-marker-alt" />
                </span>
                <p>
                    <span className="bold">DDV Verhuur Meulebeke </span><br></br> <a className="bold" href="tel:+32 51792735">+32 51792735</a>
                </p>
            </li>
            <li key="2">
                <span className="rent-locations__icon">
                    <FontAwesomeIcon icon="map-marker-alt" />
                </span>
                <p>
                    <span className="bold">DDV Verhuur Oostende</span><br></br> <a className="bold" href="tel:+32 51792735">+32 51792735</a>
                </p>
            </li>
        </ul>
    </div>
)

export default RentLocations