import React from 'react';
import { HeroHome } from '../components/machineshero';
import Layout from '../components/layout';
import { AdditionalFleet } from '../components/additionalFleet';
import { Usps } from '../components/Usps';
import SEO from '../components/seo';

export function Index() {
  return (
    <Layout>
      <SEO
        title="DDV Verhuur - Huur uw voertuigen"
        description="Huur uw auto of bestelwagen bij DDV Verhuur in Oostende en Meulbeke"
        follow={true}
        index={true}
      ></SEO>
      <HeroHome />
      <AdditionalFleet />
      <Usps />
    </Layout>
  );
}

export default Index;
