import { useState } from 'react';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { Link } from 'gatsby';

const VehicleForm = ({ extradata, formSubmitted, setFormSubmitted }) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const onSubmit = (data) => {
    data.extradata = extradata;

    const schema = {
      data: {
        request: {
          data,
        },
        name: data.naam,
        email: data.email,
        phone: data.phone,
        message: data.bericht,
        title: data.extradata.title,
        lastprice: data.extradata.lastprice,
        baseprice: data.extradata.baseprice,
        pickupdate: data.extradata.pickupDate,
        returndate: data.extradata.returnDate,
        pickuplocation: data.extradata.pickupLocation,
        extras: data.extradata.extra?.toString() || 'no extra',
      },
    };

    postData(schema);
  };

  const postData = async (schema) => {
    try {
      const response = await axios.post(
        'https://ddv-web-cms.herokuapp.com/api/requests',
        schema
      );
      setFormSubmitted(true);
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  };

  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);

  return (
    <>
      <h2>Maak uw offerte-aanvraag compleet</h2>
      <p>
        Laat uw contactgegevens achter en wij nemen contact met u op. U kunt ons
        ook telefonisch bereiken op nummer:{' '}
        <a href="tel:+3251792735">+32 51 79 27 35</a>
      </p>
      {!formSubmitted && (
        <form onSubmit={handleSubmit(onSubmit)} className="form-wrapper">
          {/* TODO: Add bedrijfsnaam if excl. VAT */}
          <label>Naam</label>
          <input
            type="text"
            {...register('naam', { required: true, maxLength: 180 })}
          />
          {errors.naam && <p>Controleer dit veld</p>}
          <label>E-mail</label>
          <input
            type="text"
            {...register('email', {
              required: true,
              pattern:
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            })}
          />
          {errors.email && <p>Controleer dit veld</p>}
          <label>Telefoon</label>
          <input
            type="tel"
            {...register('phone', {
              required: true,
              maxLength: 11,
              minLength: 8,
            })}
          />
          {errors.phone && <p>Controleer dit veld</p>}

          <label>Opmerkingen</label>
          <textarea rows="4" {...register('bericht')} />
          {errors.bericht && <p>Controleer dit veld</p>}

          <input type="submit" value="Offerte opvragen" />
        </form>
      )}
      {formSubmitted && (
        <>
        <h3>Bedankt, wij hebben uw aanvraag in goede orde ontvangen. Wij komen spoedig bij u terug.</h3>
        <Link to='/'>Keer terug naar home</Link>
        </>
      )}
    </>
  );
};

export default VehicleForm;
