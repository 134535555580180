import Vehicles from '../components/vehicles';
import Layout from '../components/layout';
import { Container } from '@mui/material';
import CategoryLinks from '../components/categories/category-links';
import SEO from '../components/seo';
import { useState, useEffect } from 'react';
import axios from 'axios';

const Aanbod = () => {
  const [autos, setAutos] = useState([]);

  useEffect(() => {
    const auto = [];
    axios
      .get(`https://ddv-web-cms.herokuapp.com/api/all-autos`)
      .then((res) => {
        setAutos(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <Layout>
      <SEO
        title="Ontdek het gehele aanbod van DDV Verhuur"
        description="DDV Verhuur heeft een groot wagenpark. Personenautos, bestelwagens, koelwagens en meer. Ontdek het!"
        follow={true}
        index={true}
      ></SEO>
      <section>
        <Container>
          <CategoryLinks />
          <h1>Aanbod</h1>
          {autos && autos.length !== 0 && <Vehicles vehicle={autos} />}
        </Container>
      </section>
    </Layout>
  );
};

export default Aanbod;
