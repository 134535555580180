import Settings from '../settings.json';
import Dumper from '../../images/svg/dumper.png';
import Excavator from '../../images/svg/excavator.png';
import Trailer from '../../images/svg/aanhangwagen.svg';
import Machine from '../../images/svg/icons8-crane-truck.png';
import Loader from '../../images/svg/loader.png';
import { Link } from 'gatsby';

const MachineCategoryLinks = ({ groupId }) => {
  const mapIdtoGroup = Settings.groups.filter(
    (groups) => groups.id === groupId
  );

  return (
    <div className="cta-categories" id="cta-categories">
      <Link to="/minigraafkraan-huren" className="cta-category">
        <div
          className={`${
            mapIdtoGroup.length > 0
              ? mapIdtoGroup[0].name === 'minigraaf_kranen_tot_1' &&
                'active-icon'
              : ''
          }`}
        >
          <img
            className="ddv-icon"
            src={Excavator}
            alt="minigraaf_kranen_tot_1"
          ></img>
          Mini graafkranen
        </div>
      </Link>
      <Link to="/dumper-huren" className="cta-category">
        <div
          className={`${
            mapIdtoGroup.length > 0
              ? mapIdtoGroup[0].name === 'dumpers' && 'active-icon'
              : ''
          }`}
        >
          <img className="ddv-icon" src={Dumper} alt="dumper"></img>
          Dumpers
        </div>
      </Link>
      <Link to="/kniklader-huren" className="cta-category">
        <div
          className={`${
            mapIdtoGroup.length > 0
              ? mapIdtoGroup[0].name === 'knikladers' && 'active-icon'
              : ''
          }`}
        >
          <img className="ddv-icon" src={Loader} alt="dumper"></img>
          Knikladers
        </div>
      </Link>
      <Link to="/kraan-huren" className="cta-category">
        <div
          className={`${
            mapIdtoGroup.length > 0
              ? mapIdtoGroup[0].name === 'kraan' && 'active-icon'
              : ''
          }`}
        >
          <img className="ddv-icon" src={Machine} alt="kraan"></img>
          Kraan
        </div>
      </Link>
      <Link to="/machinetrailer-huren" className="cta-category">
        <div
          className={`${
            mapIdtoGroup.length > 0
              ? mapIdtoGroup[0].name === 'trailers' && 'active-icon'
              : ''
          }`}
        >
          <img className="ddv-icon" src={Trailer} alt="machinetrailer"></img>
          Trailer
        </div>
      </Link>
    </div>
  );
};

export default MachineCategoryLinks;
