import { Container } from "@mui/material";
import { useStaticQuery, graphql } from "gatsby"

const usps = [
    {
        title: 'Complete service & 24/7 telefonische bijstand',
        image: 'https://ddv-verhuur.be/uploads/files/service_item/cog.svg',
        text: 'Van advies bij de keuze van je voertuig tot telefonische bijstand voor eventuele problemen onderweg.'
    },
    {
        title: 'Complete service & 24/7 telefonische bijstand',
        image: 'https://ddv-verhuur.be/uploads/files/service_item/cog.svg',
        text: 'Van advies bij de keuze van je voertuig tot telefonische bijstand voor eventuele problemen onderweg.'
    },
    {
        title: 'Complete service & 24/7 telefonische bijstand',
        image: 'https://ddv-verhuur.be/uploads/files/service_item/cog.svg',
        text: 'Van advies bij de keuze van je voertuig tot telefonische bijstand voor eventuele problemen onderweg.'
    }
]

export const Usps = () => {

    const data = useStaticQuery(graphql`
    {
      strapiUsp {
        usps {
          image {
            url
            caption
            alternativeText
            strapi_id
          }
          body
          title
          strapi_id
        }
        title
      }
    }
  `)

  const usps = data.strapiUsp.usps

    return (
        <section>
            <div className='usps__wrapper'>
                <Container>
                    <h2 style={{ textAlign: 'center' }}>{usps.title}</h2>
                    <div className='row-33'>
                        {usps.map((usp) => (
                            <div key={usp.strapi_id}>
                                <div><img src={usp.image.url} alt={usp.image.alternativeText} title={usp.image.caption} style={{ borderBottom: '1px solid blue', paddingBottom: '1rem', width:50, height:50 }} /></div>
                                <div><h3>{usp.title}</h3></div>
                                <div><p>{usp.body}</p></div>
                            </div>
                        ))}
                    </div>
                </Container>
                <div className='usp__bg-image'></div>
            </div>
        </section>
    )
}