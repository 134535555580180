import { Container } from '@mui/system';
import { useDispatch, useSelector } from 'react-redux';
import CustomerBody from '../components/customer-portal/customerBody';
import FindReservation from '../components/customer-portal/findReservation';
import '../styles/customers.scss';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import CheckoutForm from '../components/customer-portal/CheckoutForm';
import { Dialog } from '@mui/material';
import { removeClientSecret } from '../redux/customerSlice';
import Layout from '../components/layout';

const stripePromise = loadStripe('pk_test_VOOyyYjgzqdm8I3SrBqmh9qY');

const CheckinPage = (props) => {
  const { clientSecret, openDialog } = useSelector((state) => state.customer);

  const dispatch = useDispatch();

  const appearance = {
    theme: 'stripe',
  };
  const options = {
    clientSecret,
    appearance,
  };

  const handleClose = () => {
    dispatch(removeClientSecret());
  };

  return (
    <Layout>
      <Container
        style={{ paddingTop: 48, paddingBottom: 48, minHeight: '70vh' }}
      >
        <Dialog onClose={() => handleClose()} open={openDialog} fullScreen>
          {/* <Elements options={options} stripe={stripePromise}>
            <CheckoutForm />
          </Elements> */}
        </Dialog>
        <FindReservation props={props} />
        <CustomerBody />
      </Container>
    </Layout>
  );
};

export default CheckinPage;
