import { useEffect, useState } from "react";
import { loadStripe, StripeElementsOptions } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { Rentvisie } from "@bakkie/ratality";

import StripeComponent from "../../components/stripe/stripe-component";
import { Container } from "@mui/system";
import ReservationHeader from '../../components/reservation/shared/reservation-header';

const stripePromise = loadStripe("pk_live_51LXgVIIx5gYUGVHnCjT6ibgdhe5pcBJTlNXSlK4eir4hdUqQohO7ETwkPn0V6yaISHvn4P4ciaDfxwmyGwwd4Gla00MdzTvQNe");


export default function StripePage(props) {
    const [clientSecret, setClientSecret] = useState("");
    const query = new URLSearchParams(props.location.search);

    const reservationReference = query.get('reservationReference');

    useEffect(() => {
        Rentvisie.setConfig({
            baseUrl: 'https://rentvisie-proxy-aws.herokuapp.com/api',
            client: 'ddv',
            useGuestToken: true
        })
        // Create PaymentIntent as soon as the page loads
        Rentvisie.paymentIntent(reservationReference)
            .then(({ clientSecret }) => setClientSecret(clientSecret))
    }, []);

    // check themes...
    const options: StripeElementsOptions = {
        clientSecret,
        appearance: {
            theme: 'stripe',
            variables: {
                colorPrimary: '#007aff',
                colorBackground: '#f5f5f5',
                colorText: '#30313d',
                colorDanger: '#df1b41',
                spacingUnit: '10px',
                borderRadius: '4px',
                // See all possible variables below
            }
        },
    };

    return (
        <Container style={{ marginTop: "100px" }}>
            <ReservationHeader activeStepIndex={3}></ReservationHeader>
            <h2>Maak uw bestelling compleet.</h2>
            {
                clientSecret &&
                <Elements options={options} stripe={stripePromise}>
                    {/* TODO: ADD RESERVATIONREFRENCE AND BOOKINGREFERENCE */}
                    <StripeComponent clientSecret={clientSecret} />
                </Elements>
            }
        </Container >

    );
}