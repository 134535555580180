import React, { useState } from 'react';
import Settings from '../../../components/settings.json';
import Bestelwagen from '../../../images/svg/icons8-van.png';
import Personenwagen from '../../../images/svg/personenwagen.svg';
import Machine from '../../../images/svg/icons8-crane-truck.png';
import Minibus from '../../../images/svg/minibus.png';
import Koelwagen from '../../../images/svg/flaticon-freeze-truck.png';
import { navigate } from 'gatsby';
// import Luxewagen from '../../../images/svg/luxewagen.svg';
// import Trailer from '../../../images/svg/aanhangwagen.svg';

const Categories = ({ category }) => {
  if (!category) {
    category = Settings.categoryId.personenwagen;
  } else {
    switch (category) {
      case 'bestelwagen':
        category = Settings.categoryId.bestelwagen;
        break;
      case 'personenwagen':
        category = Settings.categoryId.personenwagen;
        break;
      case 'luxewagen':
        category = Settings.categoryId.luxewagen;
        break;
      case 'minibus':
        category = Settings.categoryId.minibus;
        break;
      case 'machine':
        category = Settings.categoryId.machine;
        break;
      case 'koelwagen':
        category = Settings.categoryId.koelwagen;
        break;
    }
  }

  const handleDefaultChecked = (categoryId) => categoryId === Number(category);

  return (
    <div className="cta-categories" id="cta-categories">
      <input
        type="radio"
        id={Settings.categoryId.personenwagen}
        name="categoryId"
        value={Settings.categoryId.personenwagen}
        defaultChecked={handleDefaultChecked(Settings.categoryId.personenwagen)}
      ></input>
      <label
        className="cta-category"
        htmlFor={Settings.categoryId.personenwagen}
      >
        <div>
          <img
            className="ddv-icon"
            src={Personenwagen}
            alt="personenwagens"
          ></img>
          Personenwagens
        </div>
      </label>
      {/* <input
        type="radio"
        id={Settings.categoryId.luxewagen}
        name="categoryId"
        value={Settings.categoryId.luxewagen}
        defaultChecked={handleDefaultChecked(Settings.categoryId.luxewagen)}
      ></input>
      <label className="cta-category" htmlFor={Settings.categoryId.luxewagen}>
        <div>
          <img className="ddv-icon" src={Luxewagen} alt="luxewagens"></img>
          Luxewagens
        </div>
      </label> */}

      <input
        type="radio"
        id={Settings.categoryId.minibus}
        name="categoryId"
        value={Settings.categoryId.minibus}
        defaultChecked={handleDefaultChecked(Settings.categoryId.ambulance)}
      ></input>
      <label className="cta-category" htmlFor={Settings.categoryId.minibus}>
        <div>
          <img
            className="ddv-icon"
            src={Minibus}
            alt="minibussen"
            style={{ scale: '0.8' }}
          ></img>
          Minibussen
        </div>
      </label>

      <input
        type="radio"
        id={Settings.categoryId.bestelwagen}
        name="categoryId"
        value={Settings.categoryId.bestelwagen}
        defaultChecked={handleDefaultChecked(Settings.categoryId.bestelwagen)}
      ></input>

      <label className="cta-category" htmlFor={Settings.categoryId.bestelwagen}>
        <div>
          <img
            className="ddv-icon"
            src={Bestelwagen}
            alt="bestelwagens"
            style={{ scale: '0.8' }}
          ></img>
          Bestelwagens
        </div>
      </label>
      <input
        type="radio"
        id={Settings.categoryId.koelwagen}
        name="categoryId"
        value={Settings.categoryId.koelwagen}
        defaultChecked={handleDefaultChecked(Settings.categoryId.koelwagen)}
      ></input>
      <label className="cta-category" htmlFor={Settings.categoryId.koelwagen}>
        <div>
          <img
            className="ddv-icon"
            src={Koelwagen}
            alt="koelwagens"
            style={{ scale: '0.8' }}
          ></img>
          Koelwagens
        </div>
      </label>

      {/* <Link className="cta-category cta-category-change" to="/machine-verhuur"> */}
      <label
        className="cta-category"
        onClick={() => navigate('/machine-verhuur')}
      >
        <div>
          <img className="ddv-icon" src={Machine} alt="autos"></img>
          Machines
        </div>
      </label>
    </div>
  );
};

export default Categories;
