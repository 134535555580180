import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

const initialState = {
  includeVat:
    typeof window !== 'undefined' ? localStorage.getItem('includeVat') : 'no',
};

const vatSlice = createSlice({
  name: 'vat',
  initialState,
  reducers: {
    setVat: (state, action: PayloadAction<string>) => {
      //payload must be string because localeStorage accepts only string.
      // Note: you should be able to use stingify();
      localStorage.setItem('includeVat', action.payload);
      state.includeVat = action.payload;
    },
  },
});

export const { setVat } = vatSlice.actions;
export default vatSlice.reducer;
