import { Container } from '@mui/material';
import { useStaticQuery, graphql } from 'gatsby';
import Fleetrow from './fleetRow';
import FleetSlider from './fleet/fleetSlider';

export const StaticFleet = () => (
  <section className="bg-medium-gray">
    <Container>
      <h2>Onze modellen</h2>
      <p>
        Enkele van de voertuigen die wij in onze verhuurfloot hebben zitten.
      </p>
      <FleetSlider />
    </Container>
  </section>
);

export const OurFleet = () => {
  const data = useStaticQuery(graphql`
    {
      strapiWagenpark {
        productrows {
          image {
            url
            alternativeText
            caption
          }
          vanaf
          url
          naam
          linkText
          strapi_id
          body {
            data {
              body
            }
          }
        }
      }
    }
  `);
  return (
    <section>
      <Container>
        <h2 className="text-center">Ons Wagenpark</h2>
        {data.strapiWagenpark.productrows.slice(0, 2).map((row) => (
          <Fleetrow row={row}></Fleetrow>
        ))}
      </Container>
    </section>
  );
};
