import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { Container } from '@mui/system';
import Vehicles from '../components/vehicles';
import Faqs from '../components/faqs/Faqs';
import ReactMarkdown from 'react-markdown';
import { Link } from 'gatsby';
import MachineCategoryLinks from '../components/categories/machines-category-links';
import CategoryLinks from '../components/categories/category-links';
const GroupsTemplate = (props) => {
  const { seo, title, contentBottom, contentTop, faqs, groupId, products } =
    props.pageContext;

  // TODO: add Groups to Gatsby Connfig + Populate multiple levels deep
  // TODO: add Groups to Gatsby Node to create multiple pages from this template
  // Note: stick to design other index pages

  return (
    <Layout>
      <SEO
        title={seo?.metaTitle}
        description={seo?.metaDescription}
        image={seo?.metaImage.url}
        index={true}
        follow={true}
      ></SEO>
      <Container>
        {/* TODO: Add breadcrumb */}
        <div className="breadcrumbs" style={{ alignSelf: 'start' }}>
          <div className="breadcrumb">
            {products[0]?.isCar ? (
              <Link to="/">Auto</Link>
            ) : (
              <Link to="/machine-verhuur">Machines</Link>
            )}
          </div>
          <div className="breadcrumb">{title.toLowerCase()}</div>
        </div>
        {products[0]?.isCar ? (
          <CategoryLinks groupId={groupId} />
        ) : (
          <MachineCategoryLinks groupId={groupId} />
        )}

        <h1>{title}</h1>

        <div style={{ maxWidth: 769 }}>
          {contentTop && contentTop.data ? (
            <ReactMarkdown>{contentTop.data.contentTop}</ReactMarkdown>
          ) : (
            <>
              <h2>Welkom bij DDV Autoverhuur</h2>
              <p>
                DDV VERHUUR heeft een goed onderhouden wagenpark dat bestaat uit
                nieuwe, volledig omnium verzekerde voertuigen, van maximaal 4
                jaar oud.
              </p>
            </>
          )}
        </div>
        <Vehicles id={{ name: 'groupid', id: groupId }} />
        {faqs.length > 0 && (
          <div className="faqs-wrapper">
            <h2>Veelgestelde vragen</h2>
            <Faqs faqs={faqs} />
          </div>
        )}
      </Container>
      {contentBottom && contentBottom.data && (
        <div style={{ backgroundColor: 'white', padding: '48px 0' }}>
          <Container>
            <ReactMarkdown>{contentBottom.data.contentBottom}</ReactMarkdown>
          </Container>
        </div>
      )}
    </Layout>
  );
};

export default GroupsTemplate;
