import { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useForm } from "react-hook-form";
import axios from 'axios';
import Settings from "../settings.json"

const VehicleForm = ({ open, setOpen, requestedVehicle }) => {
  const handleClose = () => setOpen(false);
  const [formSubmitted, setFormSubmitted] = useState(false)
  const { register, formState: { errors }, handleSubmit } = useForm();
  const onSubmit = (data) => {
    axios.post(Settings.mailUrl, { data })
      .then(setFormSubmitted(true))
  };
  const today = new Date()
  const tomorrow = new Date(today)
  tomorrow.setDate(tomorrow.getDate() + 1)

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Wij maken graag een offerte voor u</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Voor dit voertuig maken wij graag een offerte op maat. Laat uw contactgegevens achter en wij nemen contact met u op.
          U kunt ons ook telefonisch bereiken op nummer: <a href="tel:+3251792735">+32 51 79 27 35</a>
        </DialogContentText>
        {!formSubmitted &&
          <form onSubmit={handleSubmit(onSubmit)} className='form-wrapper'>
            <label>Naam</label>
            <input
              type="text"
              {...register("naam", { required: true, maxLength: 180 })}
            />
            {errors.naam && <p>Controleer dit veld</p>}
            <label>E-mail</label>
            <input
              type="text"
              {...register("email", {
                required: true,
                pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
              })}
            />
            {errors.email && <p>Controleer dit veld</p>}
            <label>Telefoon</label>
            <input
              type="tel"
              {...register("telefoon", {
                required: true,
                maxLength: 11,
                minLength: 8
              })}
            />
            {errors.telefoon && <p>Controleer dit veld</p>}
            <label>Ophaaldatum</label>
            <input
              type="date"
              placeholder="dd-mm-yyyy"
              min={`${tomorrow.getFullYear() - tomorrow.getMonth() - tomorrow.getDate()}`}
              {...register("ophaaldatum", {
                required: true,

              })}
            />
            {errors.ophaaldatum && <p>Controleer dit veld</p>}
            <label>Retourdatum</label>
            <input
              type="date"
              {...register("retourdatum", {
                required: true,
              })}
            />
            {errors.retourdatum && <p>Controleer dit veld</p>}

            <label>Opmerkingen</label>
            <textarea
              rows="4"
              {...register("bericht")}
            />
            {errors.bericht && <p>Controleer dit veld</p>}
            <input type="hidden"
              value={requestedVehicle.description}
              {...register("voertuig")}
            />
            <input type="hidden"
              value={requestedVehicle.images[0].thumbnailUrl}
              {...register("afbeelding")}
            />
            <input type="submit" value="Offerte opvragen" />
          </form>
        }
        {formSubmitted &&
          <h3>Bedankt, wij hebben uw aanvraag in goede orde ontvangen.</h3>
        }

      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>X</Button>
      </DialogActions>
    </Dialog>

  );
}

export default VehicleForm