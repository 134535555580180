import { Container } from '@mui/material';
import { useStaticQuery, graphql } from 'gatsby';
import Fleetrow from './fleetRow';

export const AdditionalFleet = () => {
  const data = useStaticQuery(graphql`
    {
      strapiWagenpark {
        productrows {
          image {
            url
            alternativeText
            caption
          }
          vanaf
          url
          naam
          strapi_id
          linkText
          body {
            data {
              body
            }
          }
        }
      }
    }
  `);
  return (
    <section className="bg-medium-gray">
      <Container>
        {data.strapiWagenpark.productrows.slice(3, 5).map((row) => (
          <Fleetrow row={row}></Fleetrow>
        ))}
      </Container>
    </section>
  );
};
