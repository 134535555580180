import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useStaticQuery, graphql } from "gatsby"
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';

export default function Terms() {
    const [open, setOpen] = React.useState(false);
    const [scroll, setScroll] = React.useState<DialogProps['scroll']>('paper');

    const handleClickOpen = (scrollType: DialogProps['scroll']) => () => {
        setOpen(true);
        setScroll(scrollType);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const descriptionElementRef = React.useRef<HTMLElement>(null);
    React.useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [open]);

    const data = useStaticQuery(graphql`
    query {
      strapiAlgemeneVoorwaarden {
        body {
          data {
            body
          }
        }
        title
      }
    }
  `)

    return (
        <>
            <span style={{ fontSize: 12, marginTop:12 }}>Hierbij aanvaard ik de <button style={{ fontWeight: 600, textDecoration: 'underline' }} onClick={handleClickOpen('paper')}>Algemene Voorwaarden</button></span>
            <Dialog
                open={open}
                onClose={handleClose}
                scroll={scroll}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogTitle id="scroll-dialog-title">{data.strapiAlgemeneVoorwaarden.title}</DialogTitle>
                <DialogContent dividers={scroll === 'paper'}>
                    <DialogContentText
                        id="scroll-dialog-description"
                        ref={descriptionElementRef}
                        tabIndex={-1}
                    >
                        <ReactMarkdown>{data.strapiAlgemeneVoorwaarden.body.data.body}</ReactMarkdown>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Sluiten</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}


