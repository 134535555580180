import Vehicles from '../components/vehicles';
import Layout from '../components/layout';
import { Container } from '@mui/material';
import MachineCategoryLinks from '../components/categories/machines-category-links';
import SEO from '../components/seo';
import { useState, useEffect } from 'react';
import axios from 'axios';

const Machines = () => {
  const [machines, setMachines] = useState([]);
  useEffect(() => {
    axios
      .get(`https://ddv-web-cms.herokuapp.com/api/all-machines`)
      .then((res) => {
        setMachines(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <Layout>
      <SEO
        title="Ontdek het gehele aanbod van DDV Verhuur"
        description="DDV Verhuur heeft een groot wagenpark. Personenautos, bestelwagens, koelwagens en meer. Ontdek het!"
        follow={true}
        index={true}
      ></SEO>
      <section>
        <Container>
          <MachineCategoryLinks />
          <h1>Aanbod</h1>
          {machines && machines.length !== 0 && <Vehicles vehicle={machines} />}
        </Container>
      </section>
    </Layout>
  );
};

export default Machines;
