import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import { useSelector } from 'react-redux';
import CustomerDocuments from './customerDocuments';
import { Button } from '@mui/material';
import { API_URL, channelId, clientId } from '../../constants/api';
import axios from 'axios';
import CustomerDeposit from './customerDeposit';

const CustomerBody = () => {
  const customer = useSelector((state) => state.customer.customer);
  const { reservationReference } = useSelector((state) => state.customer);

  const [state, setState] = useState(undefined);
  const [error, setError] = useState(undefined);

  useEffect(() => {
    if (customer) {
      setState(customer.customer);
    }
  }, [customer]);

  const handleChange = (e) => {
    setState((prevState) => {
      return { ...prevState, [e.target.name]: e.target.value };
    });
  };

  const handleChangeCompany = (e) => {
    setState((prevState) => {
      return {
        ...prevState,
        company: {
          ...prevState.company,
          [e.target.name]: e.target.value,
        },
      };
    });
  };

  const handleChangeAddress = (e) => {
    setState((prevState) => {
      return {
        ...prevState,
        physicalAddress: {
          ...prevState.physicalAddress,
          [e.target.name]: e.target.value,
        },
      };
    });
  };

  const handleChangePhone = (e) => {
    setState((prevState) => {
      return {
        ...prevState,
        cellphoneNumber: {
          ...prevState.cellphoneNumber,
          [e.target.name]: e.target.value,
        },
      };
    });
  };

  const handleChangeDL = (e) => {
    setState((prevState) => {
      return {
        ...prevState,
        identityDocument: {
          ...prevState.identityDocument,
          [e.target.name]: e.target.value,
        },
      };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await axios.patch(
        `${API_URL}/reservations/${reservationReference}/bookings/${reservationReference}01/customer`,
        state,
        {
          headers: {
            clientId,
            channelId,
          },
        }
      );
      alert('Gegevens bijgewerkt');
      setError('');
    } catch (e) {
      setError('Gegevens niet opgeslagen, probeer het opnieuw.');
    }

    // TODO: Add validation
  };

  return (
    <div className="customer-fields-wrapper">
      {state && customer && (
        <>
          {customer.rentalStatus?.toUpperCase() === 'COMPLETED' && (
            <p>Reservering voltooid, download uw documenten hieronder.</p>
          )}
          {customer.rentalStatus?.toUpperCase() !== 'COMPLETED' && (
            <form className="customer-fields-wrapper" onSubmit={handleSubmit}>
              <p className="border-bottom">Factuurgegevens </p>
              <div className="customer-fields-row">
                <TextField
                  variant="outlined"
                  margin="dense"
                  value={state.company?.name || ''}
                  onChange={handleChangeCompany}
                  label="Factuurnaam"
                  name="name"
                  required
                  fullWidth
                ></TextField>
                <TextField
                  variant="outlined"
                  margin="dense"
                  value={state.company?.vatNumber || ''}
                  onChange={handleChangeCompany}
                  label="BTW Nummer (optioneel)"
                  name="vatNumber"
                  fullWidth
                ></TextField>
              </div>

              <p className="border-bottom">Contactpersoon *</p>
              <div className="customer-fields-row">
                <TextField
                  variant="outlined"
                  margin="dense"
                  value={state.firstName || ''}
                  onChange={handleChange}
                  label="Voornaam"
                  required
                  name="firstName"
                  fullWidth
                ></TextField>
                <TextField
                  variant="outlined"
                  margin="dense"
                  value={state.lastName || ''}
                  onChange={handleChange}
                  label="Achternaam"
                  required
                  name="lastName"
                  fullWidth
                ></TextField>
              </div>

              <p className="border-bottom">Adres *</p>
              <div className="customer-fields-row">
                <TextField
                  variant="outlined"
                  margin="dense"
                  value={state.physicalAddress?.postalCode || ''}
                  onChange={handleChangeAddress}
                  required
                  label="Postcode"
                  name="postalCode"
                  fullWidth
                ></TextField>
                <TextField
                  variant="outlined"
                  margin="dense"
                  value={state.physicalAddress?.houseNumber || ''}
                  onChange={handleChangeAddress}
                  label="Huisnummer"
                  required
                  name="houseNumber"
                  fullWidth
                ></TextField>
                <TextField
                  variant="outlined"
                  margin="dense"
                  value={state.physicalAddress?.houseNumberAdditional || ''}
                  onChange={handleChangeAddress}
                  label="Toevoeging"
                  name="houseNumberAdditional"
                ></TextField>
              </div>
              <div className="customer-fields-row">
                <TextField
                  variant="outlined"
                  margin="dense"
                  value={state.physicalAddress?.streetName || ''}
                  onChange={handleChangeAddress}
                  label="Straat"
                  required
                  name="streetName"
                  fullWidth
                ></TextField>
                <TextField
                  variant="outlined"
                  margin="dense"
                  value={state.physicalAddress?.city || ''}
                  onChange={handleChangeAddress}
                  label="Stad"
                  name="city"
                  required
                  fullWidth
                ></TextField>
              </div>

              <p>Contact *</p>

              <div className="customer-fields-row">
                <TextField
                  variant="outlined"
                  margin="dense"
                  value={state.cellphoneNumber?.countryDialingCode || ''}
                  onChange={handleChangePhone}
                  label="Land Code (vb. +31)"
                  name="countryDialingCode"
                  required
                ></TextField>
                <TextField
                  variant="outlined"
                  margin="dense"
                  value={state.cellphoneNumber?.number || ''}
                  onChange={handleChangePhone}
                  label="Telefoonnummer"
                  name="number"
                  required
                  fullWidth
                ></TextField>
                <TextField
                  variant="outlined"
                  margin="dense"
                  value={state.email}
                  onChange={handleChange}
                  label="E-mail"
                  name="email"
                  required
                  fullWidth
                ></TextField>
              </div>

              <p className="border-bottom">Rijbewijs </p>
              <div className="customer-fields-row">
                <TextField
                  variant="outlined"
                  margin="dense"
                  value="Rijbewijs"
                  disabled
                  label="Type Document"
                  name="documentType"
                  onClick={() => {
                    alert('Je kunt dit veld niet wijzigen.');
                  }}
                ></TextField>
                <TextField
                  variant="outlined"
                  margin="dense"
                  value={state.identityDocument?.number || ''}
                  onChange={handleChangeDL}
                  label="Rijbewijsnummer"
                  name="number"
                  fullWidth
                ></TextField>
              </div>
              <div style={{ textAlign: 'right', marginTop: 12 }}>
                <Button type="submit" variant="contained" size="large">
                  Gegevens updaten
                </Button>
              </div>
              {error && <p>{error}</p>}
            </form>
          )}

          {/* {customer.rentalStatus?.toUpperCase() !== 'COMPLETED' && (
            <CustomerDeposit />
          )} */}

          <CustomerDocuments />
        </>
      )}
    </div>
  );
};

export default CustomerBody;
