import { Rentvisie } from '@bakkie/ratality';
import { useState, useEffect, useRef } from 'react';
import Skeletons from '../loading/skeletons';
import ArrowRight from '../../images/icons/chevron-right.png';
import ArrowLeft from '../../images/icons/chevron-left.png';
import { Link } from 'gatsby';
import { Container } from '@mui/system';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { manipulatePriceForVat, manipulatePriceTagForVat } from '../../utils/vatHelper';

const FleetSlider = () => {
    const { includeVat } = useSelector((state: RootState) => state.vat);


    const [loading, setLoading] = useState(true)
    const [vehicleClasses, setVehicleClasses] = useState([])
    const [error, setError] = useState(undefined)

    const ref = useRef(null)

    const scroll = (scrollOffset) => {
        ref.current.style.scrollBehavior = "smooth";
        ref.current.scrollLeft += scrollOffset
    }

    useEffect(() => {
        Rentvisie.setConfig({
            baseUrl: 'https://rentvisie-proxy-aws.herokuapp.com/api',
            client: 'ddv',
            useGuestToken: true
        })

        Rentvisie.getVehicleClasses() // 1567 branch Purmerend
            .then(setVehicleClasses)
            .catch(setError)
            .finally(() => setLoading(false))
    }, [])
    if (loading) {
        return (
            <Skeletons />
        )
    }
    if (error) {
        return (
            <p>{error.message}</p>
        )
    }

    return (
        <div className="cards__wrapper">
            <div className='cards__overflow' ref={ref}>
                {vehicleClasses.map((item) => (
                    <Link to={`/${item.subLabelMarkup?.replace(/(<([^>]+)>)/gi, "").toLowerCase().replaceAll(" ", "-")}`} className="card" key={item.vehicleClassId}>
                        <div className="card__content">
                            <div className='card__title-wrapper'>
                                <div className='card__title'>
                                    <h3>{item.description}</h3>
                                </div>
                                <div className='card__info-icon'>
                                    i
                                </div>
                            </div>
                            <div className='card__image'>
                                <img src={
                                    item.images[0]
                                        ? item.images[0].imageUrl
                                        : ''
                            } alt={item.description} style={{ maxWidth: '100%' }} />
                            </div>
                            <div className='card__features'>
                            </div>
                            <div className='card__price'>
                                Vanaf € {' '}

                                {item.marketingPrices
                                    ? manipulatePriceForVat(item.marketingPrices[0].value, includeVat)
                                    : '0'}
                                {manipulatePriceTagForVat(includeVat)}
                            </div>
                        </div>
                        <div className='card__more-info'>
                            Meer info
                        </div>
                    </Link>
                ))}
            </div>
            <button onClick={() => scroll(-350)} className='cards__scroll-left-button'><img src={ArrowLeft} alt='left' width={24} /></button>
            <button onClick={() => scroll(350)} className='cards__scroll-right-button'><img src={ArrowRight} alt='right' width={24} /></button>
        </div>
    )
}

export default FleetSlider;