import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ReactMarkdown from 'react-markdown';
import DownIcon from '../../images/icons/chevron-down.png'

const Faqs = ({ faqs }) => {
    return (
        faqs.map((faq) => (
            <Accordion key={faq.strapi_id}>
                <AccordionSummary
                    expandIcon={<img src={DownIcon} alt='arrow'></img>}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography style={{ fontWeight: 600 }}>{faq.vraag}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        <ReactMarkdown>{faq.antwoord.data.antwoord}</ReactMarkdown>
                    </Typography>
                </AccordionDetails>
            </Accordion>
            ))
    )
}

export default Faqs;