import React, { useState } from 'react';
import Select from 'react-select';
import Settings from '../../settings.json';
const options = Settings.locations;

// const Select = props => (
//   <FixRequiredSelect
//     {...props}
//     SelectComponent={BaseSelect}
//     options={props.options || options}
//   />
// );

const LocationSelect = (props, noBox) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      // Overwrittes the different states of border
      borderColor: '#E51C3D',
      // Removes weird border around container
      boxShadow: state.isFocused ? null : null,
      '&:hover': {
        // Overwrittes the different states of border
        cursor: 'pointer',
        borderColor: '#E51C3D',
      },
      color: '#000',
      height: 50,
      fontWeight: 500,
      fontSize: 14,
    }),
    menu: (provided, state) => ({
      ...provided,
      width: 300,
      borderBottom: '1px dotted black',
      color: state.selectProps.menuColor,
      '&:hover': { cursor: 'pointer' },
    }),
    option: (provided, state) => ({
      ...provided,
      borderBottom: '1px dotted black',
      fontWeight: state.isSelected ? 'bold' : 'normal',
      background: state.isSelected ? 'skyblue' : 'white',
      color: 'black',
      '&:active': { color: 'black' },
      '&:hover': { cursor: 'pointer', background: 'lightskyblue' },
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = 'opacity 300ms';

      return { ...provided, opacity, transition };
    },
  };

  let defaultValue = options[0];

  if (props.location !== undefined) {
    defaultValue = options.find(option => option.value === parseInt(props.location));
  }

  return (
    <Select
      name="location"
      defaultValue={defaultValue} // we need an object here
      onChange={setSelectedOption}
      options={options}
      className="react-select-container"
      classNamePrefix="react-select"
      styles={customStyles}
      required
    />
  );
};

export default LocationSelect;
