import * as React from 'react';
import { Rentvisie } from '@bakkie/ratality';
import ReservationSelectVehicle from '../../components/reservation/step-1';
import ReservationHeader from '../../components/reservation/shared/reservation-header';
import { Container } from '@mui/system';
import Layout from '../../components/layout';
import Skeletons from '../../components/loading/skeletons';
import SEO from '../../components/seo';
import { Link } from 'gatsby';

const VoertuigKiezen = (props) => {
  const [availableRentals, setAvailableRentals] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(undefined);

  const query = new URLSearchParams(props.location.search);
  const categoryId = query.get('categoryId');
  // const categoryId = 381;
  const vehicleClassId = query.get('vehicleClassId');
  // const location = 4718;
  const location = query.get('location');

  // Parse PickupDateTime
  const pickupDateTime = query.get('pickupDate') || '31-12-2022 08:00';
  //On safari it sanitizes the comma's in the date wrongly. This is a workaround
  const pickupDate = pickupDateTime.split(' ')[0].replace(/,+/g, '');
  const pickupTimeSplit = pickupDateTime.split(' ')[1];
  const pickupTime =
    pickupTimeSplit.split(':')[0] + ':' + pickupTimeSplit.split(':')[1];

  // Parse ReturnDateTime
  const returnDateTime = query.get('returnDate') || '31-12-2022 17:00';
  //On safari it sanitizes the comma's in the date wrongly. This is a workaround
  const returnDate = returnDateTime.split(' ')[0].replace(/,+/g, '');

  const returnTimeSplit = returnDateTime.split(' ')[1];
  const returnTime =
    returnTimeSplit.split(':')[0] + ':' + returnTimeSplit.split(':')[1];

  React.useEffect(() => {
    Rentvisie.setConfig({
      baseUrl: 'https://api.rentvisie.com/api',
      client: 'ddv',
      useGuestToken: true,
    });

    const pickupLocationId = location || 6017;
    const dropOffLocationId = location || 6017;

    const filters = {
      pickupDate: pickupDate,
      pickupTime: pickupTime,
      dropOffDate: returnDate,
      dropOffTime: returnTime,
      vehicleClassId: vehicleClassId || '',
      categoryId: categoryId || '',
    };

    Rentvisie.getRentals(pickupLocationId, dropOffLocationId, filters)
      .then(setAvailableRentals)
      .catch(setError)
      .finally(() => setLoading(false));
  }, []);

  if (loading) {
    return (
      <Container>
        <ReservationHeader activeStepIndex={1} isCar={true} />
        <div className="reservation__title">
          <h2>Beschikbare Voertuigen</h2>
        </div>
        <Skeletons />
      </Container>
    );
  }

  if (error) {
    return (
      <Layout>
        <Container>
          <h2>Er is iets misgegaan</h2>
          <Link to="/">Terug naar home</Link>
        </Container>
      </Layout>
    );
  }

  //niet zo netjes...2 keer zelfde variablele, hoie gebruik ik filters ipv searchargs ?
  const searchArgs = {
    pickupDate: pickupDate,
    pickupTime: pickupTime,
    dropOffDate: returnDate,
    dropOffTime: returnTime,
    vehicleClassId: vehicleClassId || '',
    pickupLocationId: location || 5301,
    dropOffLocationId: location || 5301,
  };

  return (
    <div>
      <SEO
        title="Huur uw voertuig bij DDV"
        description="Voordelig en snel geregeld bij DDV Autoverhuur"
        follow={true}
        index={false}
      ></SEO>
      <ReservationSelectVehicle
        availableRentals={availableRentals}
        searchArgs={searchArgs}
      />
    </div>
  );
};

export default VoertuigKiezen;
