import { configureStore } from '@reduxjs/toolkit';
import customerReducer from './customerSlice';
import datelocationReducer from './datelocationSlice';
import vatReducer from './vatSlice';

export const store = configureStore({
  reducer: {
    customer: customerReducer,
    datelocation: datelocationReducer,
    vat: vatReducer,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
