import Layout from '../components/layout';
import SEO from '../components/seo';
import { Container } from '@mui/system';
import Vehicles from '../components/vehicles';
import CategoryLinks from '../components/categories/category-links';
import Faqs from '../components/faqs/Faqs';
import ReactMarkdown from 'react-markdown';

const Categories = (props) => {
  const { title, categoryId, seoBasic, contentBottom, contentTop, faqs } =
    props.pageContext;

  return (
    <Layout>
      {/* <SEO></SEO>  */}
      <SEO
        title={seoBasic.seoTitle}
        description={seoBasic.seoMetadescription}
        index={true}
        follow={true}
      ></SEO>
      <Container>
        {/* TODO: Add breadcrumb */}
        <h1>{title}</h1>
        <h1> hi</h1>
        <div className="categories-wrapper">
          <CategoryLinks categoryId={categoryId} />
        </div>
        <div style={{ maxWidth: 769 }}>
          {contentTop && contentTop.data ? (
            <ReactMarkdown>{contentTop.data.contentTop}</ReactMarkdown>
          ) : (
            <>
              <h2>Welkom bij DDV Autoverhuur</h2>
              <p>
                DDV VERHUUR heeft een goed onderhouden wagenpark dat bestaat uit
                nieuwe, volledig omnium verzekerde voertuigen, van maximaal 4
                jaar oud.
              </p>
            </>
          )}
        </div>
        <Vehicles id={{ name: 'categoryId', id: categoryId }} />
        {faqs.length > 0 && (
          <div className="faqs-wrapper">
            <h2>Veelgestelde vragen</h2>
            <Faqs faqs={faqs} />
          </div>
        )}
      </Container>
      {contentBottom && contentBottom.data && (
        <div style={{ backgroundColor: 'white', padding: '48px 0' }}>
          <Container>
            <ReactMarkdown>{contentBottom.data.contentBottom}</ReactMarkdown>
          </Container>
        </div>
      )}
    </Layout>
  );
};

export default Categories;
