/* eslint-disable array-callback-return */
import { useState, useEffect, useRef } from 'react';
import QuoteForm from '../../components/modals/quote_quoteForm';
import ReservationHeader from '../../components/reservation/shared/reservation-header';
import SEO from '../../components/seo';
import axios from 'axios';
import { lighten, Card, CardContent, Container } from '@mui/material';
import Settings from '../../components/settings.json';
import { useSelector } from 'react-redux';
import {
  calculateBasePrice,
  calculateExtraPrice,
  calculateTotalPrice,
  getDifferenceInDays,
  parseDate,
} from '../../utils/helper-functions';
import VatToggle from '../../components/vatToggle';
import RoomIcon from '@mui/icons-material/Room';
import TodayIcon from '@mui/icons-material/Today';
import EventIcon from '@mui/icons-material/Event';
import { Link } from 'gatsby';

const AccessoiresKiezen = (props) => {
  const includeVat = useSelector((state) => state.vat.includeVat);
  const [rental, setRental] = useState({});
  const [baseprice, setBaseprice] = useState(0);
  const [finalprice, setFinalprice] = useState(0);
  const [selectedExtras, setSelectedExtras] = useState([]);
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);


  const myRef = useRef(null);

  //query
  const query = new URLSearchParams(props.location.search);
  const id = query.get('id');
  const pickupDate = query.get('pickupDate') || '1-1-2024';
  const returnDate = query.get('returnDate') || '1-1-2024';
  const returnTime = query.get('returnTime') || '22:00';
  const pickupTime = query.get('pickupTime') || '12:00';
  const pickupLocationid = query.get('pickupLocation') || '6017';

  const pickupLocation = Settings.locations.find(
    (location) => location.value.toString() === pickupLocationid
  )?.label;

  useEffect(() => {
    const getData = async () => {
      try {
        const res = await axios.get(
          `https://ddv-web-cms.herokuapp.com/api/products/${id}?populate=deep`
        );
        setRental(res.data.data.attributes);
      } catch (e) {
        setError(true);
      }

      setLoading(false);
    };

    getData();
  }, [id]);

  const { title, mediaslider, prices, extras, isCar } = rental;

  // day calculation
  const startDate = parseDate(pickupDate);
  const endDate = parseDate(returnDate);

  const differenceDay = getDifferenceInDays(startDate, endDate);
  const totalDays = differenceDay + 1;

  useEffect(() => {
    if (prices) {
      setBaseprice(calculateBasePrice(prices, totalDays, includeVat));
    }
  }, [prices, totalDays, includeVat]);

  const clickExtraHandler = (extra) => {
    if (selectedExtras.some((item) => item.id === extra.id)) {
      setSelectedExtras(selectedExtras.filter((item) => item.id !== extra.id));
    } else {
      setSelectedExtras([...selectedExtras, extra]);
    }
  };

  useEffect(() => {
    const newPrice = calculateTotalPrice(
      baseprice,
      selectedExtras.map((extra) => extra.prijs),
      includeVat,
      totalDays
    );
    setFinalprice(newPrice);
  }, [includeVat, baseprice, selectedExtras, totalDays]);

  useEffect(() => {
    setData({
      title: title,
      finalprice: finalprice,
      baseprice: baseprice,
      pickupDate: pickupDate,
      returnDate: returnDate,
      pickupLocation: pickupLocation,
      extra: selectedExtras.map((extra) => extra.name),
    });
  }, [
    finalprice,
    pickupDate,
    returnDate,
    pickupLocation,
    title,
    selectedExtras,
    baseprice,
  ]);

  if (loading) {
    return <p>loading...</p>;
  }

  if (error) {
    return (
      <Container>
        <ReservationHeader activeStepIndex={2} isCar={isCar} />
        <h2>Er is iets misgegaan</h2>
        <p>Vernieuw de pagina.</p>
        <Link to="/">Terug naar home</Link>
      </Container>
    );
  }
  const extraCategories = [
    {
      Insurance: 'Verzekeringen',
    },
    {
      mileagePackage: 'Kilometerpakketten',
    },
    {
      engineHours: 'Draaiuren',
    },
    {
      accessory: 'Accessoires',
    },
  ];
  return (
    <>
      <Container style={{ paddingBottom: 100 }}>
        <ReservationHeader activeStepIndex={2} isCar={isCar} />
        <SEO
          title="Huur uw voertuig bij DDV"
          description="Voordelig en snel geregeld bij DDV Autoverhuur"
          follow={true}
          index={false}
        ></SEO>

        <h1>{title} huren</h1>
        {extras.length > 0 && <h2>Personaliseer jouw pakket </h2>}

        <div className="reservation-select-wrapper my-l">
          <div className="reservation-select-left">
            {!formSubmitted && (
              <div className="extra-wrapper">
                {extraCategories.map((categoryObj) => {
                  const category = Object.keys(categoryObj)[0];
                  const categoryName = categoryObj[category];
                  const matchingExtras = extras?.filter(
                    (extra) =>
                      extra.category === category ||
                      (extra.category === null && category === 'accessory')
                  );
                  const showTitle = matchingExtras && matchingExtras.length > 0;

                  return (
                    <>
                      <h3 style={showTitle ? {} : { display: 'none' }}>
                        {categoryName}
                      </h3>
                      <div className="extrabox-grid">
                        {matchingExtras &&
                          matchingExtras.map((extra) => {
                            return (
                              <Card
                                className="extrabox-nr"
                                key={extra.id}
                                sx={{
                                  backgroundColor: selectedExtras.find(
                                    (item) => item.id === extra.id
                                  )
                                    ? lighten('#007aff', 0.6)
                                    : null,
                                  height: '100%',
                                }}
                                onClick={() => clickExtraHandler(extra)}
                              >
                                <CardContent>
                                  <div
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'space-between',
                                      alignItems: 'center',
                                      fontWeight: 600,
                                      textTransform: 'uppercase',
                                    }}
                                  >
                                    <p style={{ fontWeight: 600 }}>
                                      {extra.name}
                                    </p>

                                    <p className="primary-color">
                                      {calculateExtraPrice(
                                        extra,
                                        totalDays,
                                        includeVat
                                      )}
                                      €
                                    </p>
                                  </div>
                                  <p>{extra.description}</p>
                                </CardContent>
                              </Card>
                            );
                          })}
                      </div>
                    </>
                  );
                })}
              </div>
            )}
            <div
              style={{
                border: showForm ? '2px solid red' : '',
                padding: 10,
                borderRadius: 10,
              }}
              ref={myRef}
            >
              <QuoteForm extradata={data} formSubmitted={formSubmitted} setFormSubmitted={setFormSubmitted}></QuoteForm>
            </div>
          </div>
          <div className="reservation-select-right">
            <Card
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                borderRadius: 4,
              }}
            >
              <CardContent>
                {mediaslider && (
                  <img
                    src={mediaslider.data[0].attributes.url}
                    alt={mediaslider.data[0].attributes.name}
                    width={'100%'}
                  ></img>
                )}

                <p style={{ display: 'flex', alignItems: 'center', gap: 3 }}>
                  <RoomIcon /> {pickupLocation}
                </p>
                <p style={{ display: 'flex', alignItems: 'center', gap: 3 }}>
                  <TodayIcon /> {pickupDate} {pickupTime}{' '}
                </p>
                <p style={{ display: 'flex', alignItems: 'center', gap: 3 }}>
                  <EventIcon /> {returnDate} {returnTime}{' '}
                </p>

                <p style={{ fontSize: 'small' }}>
                  Huurperiode: {totalDays} dag{totalDays > 1 && 'en'}
                </p>

                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <hr style={{ width: '100%' }} />

                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      width: '100%',
                    }}
                  >
                    <h3>Basisprijs</h3>
                    <p>
                      {calculateBasePrice(prices, totalDays, includeVat)} €{' '}
                    </p>
                  </div>
                  <hr style={{ width: '100%' }} />
                  {selectedExtras?.length > 0 && (
                    <>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          width: '100%',
                        }}
                      >
                        <div style={{ width: '100%' }}>
                          <h3>Extras</h3>

                          {selectedExtras.map((extra) => (
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                              }}
                            >
                              <p>{extra.name}</p>
                              <p>
                                {calculateExtraPrice(
                                  extra,
                                  totalDays,
                                  includeVat
                                )}{' '}
                                €
                              </p>
                            </div>
                          ))}
                        </div>
                      </div>
                      <hr style={{ width: '100%' }} />
                    </>
                  )}
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      width: '100%',
                    }}
                  >
                    <h3>Richtprijs (totaal)</h3>
                    <p>{finalprice} €</p>
                  </div>
                </div>
              </CardContent>
              <button
                disabled={showForm}
                className="primary-button"
                onClick={() => {
                  myRef.current.scrollIntoView({ behavior: 'smooth' });
                  setShowForm(true);
                }}
                style={{
                  margin: 20,
                  backgroundColor: showForm && 'grey',
                }}
              >
                {' '}
                Offerte aanvragen
              </button>
            </Card>
          </div>
        </div>

        <div style={{ display: 'flex', gap: 12 }}></div>
      </Container>
      <VatToggle />
    </>
  );
};

export default AccessoiresKiezen;
