import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { API_URL, channelId, clientId } from '../../constants/api';
import { useDispatch, useSelector } from 'react-redux';
import { setClientSecret } from '../../redux/customerSlice';
import { Button } from '@mui/material';
import PaymentsIcon from '@mui/icons-material/Payments';
import ShieldOutlinedIcon from '@mui/icons-material/ShieldOutlined';

const CustomerDeposit = () => {
  const { reservationReference } = useSelector((state) => state.customer);
  const dispatch = useDispatch();

  const [deposit, setDeposit] = useState();
  const [error, setError] = useState();
  const [loading, setLoading] = useState();

  useEffect(() => {
    const getDeposit = async () => {
      try {
        const depositStatus = await axios.get(
          `${API_URL}/reservations/${reservationReference}/deposit`,
          {
            headers: {
              clientId,
              channelId,
            },
          }
        );

        setDeposit(depositStatus.data);
      } catch (e) {
        setError('Kon borg niet ophalen');
      }
    };

    getDeposit();
  }, []);

  const getPaymentIntent = async () => {
    setLoading(true);
    try {
      const pi = await axios.get(
        `${API_URL}/reservations/${reservationReference}/deposit/payment-intent`,
        {
          headers: {
            clientId,
            channelId,
          },
        }
      );

      dispatch(setClientSecret(pi.data.clientSecret));
    } catch (e) {
      setError('Kon betaalpagina niet ophalen');
    }
    setLoading(false);
  };

  return (
    <>
      {deposit && (
        <div
          className="deposit-section customer-document-wrapper"
          style={{ maxWidth: '100%' }}
          id="deposit"
        >
          <h2 style={{ marginBottom: 6 }}>
            Openstaande Borg € {deposit.amount?.value}
          </h2>

          {!loading ? (
            <div style={{ marginBottom: 6 }}>
              <Button
                onClick={() => {
                  getPaymentIntent();
                }}
                variant="contained"
                size="large"
              >
                <PaymentsIcon style={{ marginRight: 6 }} /> Borg Betalen
              </Button>
            </div>
          ) : (
            'Ogenblik geduld...'
          )}
        </div>
      )}
      {error && <p>{error}</p>}
    </>
  );
};

export default CustomerDeposit;
