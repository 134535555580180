import { Container } from "@mui/material"
import { Link } from "gatsby"
const ContactCta = () => {
    const imageUrl = 'https://ddv-verhuur.be/uploads/images/builderblock/ddv-autoverhuur-lange-termijn-2.jpg?v=1637241305'
    
    return (
        <Container>
            <div className="contact-cta__image">
                <img src={imageUrl} alt='placeholder' style={{maxWidth:'100%'}} className='tablet'></img>
                <div className="contact-cta__image-overlay"></div>
                <div className='contact-cta__content'>
                    <h2>Interesse in huren voor langer dan 1 maand?</h2>
                    <p>Vraag nog vandaag om een prijsofferte voor het voertuig van jouw keuze.</p>
                    <Link to='/locaties-en-contact' className='button primary-button'>Contacteer ons</Link>
                </div>
            </div>
        </Container>
    )
}

export default ContactCta;