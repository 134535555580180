import React from 'react'
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import { Icon } from "leaflet";
import CarIcon from "../images/icons/car-map-icon.png"

const data = [
    {
        name: 'DDV Meulebeke',
        address: 'Pittemstraat 153',
        phone: '+32 51 79 27 35',
        email: 'info@ddv-verhuur.be',
        location: 'Meulebeke',
        url: 'https://ddv-verhuur.be/uploads/images/locations/85_1638054270_a679140b.jpg?v=1638054273',
        lat: 50.970461037806004,
        lon: 3.2755135565404316
    },
    {
        name: 'DDV Oostende',
        address: 'Jacobsenstraat 7',
        phone: '+32 51 79 27 35',
        email: 'info@ddv-verhuur.be',
        location: 'Oostende',
        url: 'https://ddv-verhuur.be/uploads/images/locations/85_1638054270_a679140b.jpg?v=1638054273',
        lat: 51.231808741517845,
        lon: 2.938681111700709
    }
]

const position = [50.970461037806004, 3.2755135565404316]

export const Map = () => {
    if (typeof window !== 'undefined') {
        
        const customIcon = new Icon({
            iconUrl: CarIcon,
        });

        return (
            <MapContainer center={position} zoom={10} scrollWheelZoom={false} style={{borderRadius: '10px', width: "100%"}}>
                <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                {data.map((item) => (
                    <Marker
                        position={[item.lat, item.lon]}
                        icon={(!!customIcon ? customIcon : null)}
                    >
                    </Marker>
                ))}

            </MapContainer>

        )
    };
    return null;
}