import Skeleton from '@mui/material/Skeleton';
import LinearBuffer from './progressbar';
const Skeletons = () => (
  <div>
    <div style={{ marginBottom: 10 }}>
      <LinearBuffer />
    </div>

    <div className="cards__grid">
      {Array.from(new Array(6)).map((item, index) => (
        <div className="card" key={index}>
          <div className="card__content">
            <div className="card__title-wrapper">
              <div className="card__title">
                <h2>
                  <Skeleton variant="text" width={200} />
                </h2>
              </div>
              <div className="card__info-icon">
                <Skeleton variant="circular" width={20} height={20} />
              </div>
            </div>
            <div className="card__image">
              <Skeleton variant="rectangular" width={320} height={240} />
            </div>
            <div className="card__features">
              <Skeleton variant="text" />
              <Skeleton variant="text" />
              <Skeleton variant="text" />
              <Skeleton variant="text" />
            </div>
          </div>
          <div className="card__price">
            <Skeleton variant="text" />
          </div>
          <div className="card__more-info">
            <Skeleton variant="text" />
          </div>
        </div>
      ))}
    </div>
  </div>
);

export default Skeletons;
