import { useEffect, useState } from "react"
import { Rentvisie } from "@bakkie/ratality"
import Layout from "../../components/layout"
import { Map } from "../../components/Map"
import RentLocations from "../../components/shared/rent-locations"
import thanksImage from "../../images/reservering-ddv-web.webp"
import PurchaseDatalayer from "./datalayers/Purchase"

const ThankYou = () => {
  //   useEffect(() => {
  //     Rentvisie.setConfig({
  //       baseUrl: 'https://rentvisie-proxy-aws.herokuapp.com/api',
  //       client: 'ddv'
  //     });

  //     const finaliseBooking = async () => {
  //       if (typeof window !== "undefined") {
  //         const bookingReference = await localStorage.getItem('bookingReference')
  //         const storedCustomerData = await localStorage.getItem('storedCustomerData')
  //         const parsedCustomerData = await JSON.parse(storedCustomerData)
  //         const reservationReference = localStorage.getItem('reservationReference');
  //         // Rentvisie.addCustomerData(reservationReference, bookingReference, parsedCustomerData)
  //         // Rentvisie.confirmationEmail(reservationReference, bookingReference)
  //       }
  //     }

  //     finaliseBooking();
  // }, [])
  const [reservationData, setReservationData] = useState({})
  const [error, setError] = useState(undefined)

  useEffect(() => {
    Rentvisie.setConfig({
      baseUrl: 'https://rentvisie-proxy-aws.herokuapp.com/api',
      client: 'ddv',
      useGuestToken: true
  })
  

    if (typeof window !== "undefined") {
      const reservationReference = localStorage.getItem('reservationReference');
      Rentvisie.getReservation(reservationReference)
        .then(setReservationData)
        .catch(setError)
    }
  }, [])

  if (error) { return (console.log('something went wrong :', error)) };

  return (
    <Layout>
      <div className="reservation-container" style={{ marginTop: 50, marginBottom: 50 }}>
        <div className="reservation__title">
          <h1>Uw reservering is bevestigd, bedankt!</h1>
          <p>U ontvangt binnen enkele seconde een e-mail ter bevestiging.</p>
          <p>
            Neemt u bij het ophalen van het voertuig ten minste de volgende documenten mee:
            <ul>
              <li>Rijbewijs</li>
              <li>Paspoort of ID-kaart</li>
              <li>Bewijs van Adres ((digitaal) Poststuk of Internet Bankieren op uw telefoon)</li>
            </ul>
          </p>
          <p>Heeft u nog vragen over uw reservering? Neem dan contact met ons op!</p>
        </div>
        <div className="row-50">
          <div className="">
            <RentLocations />
          </div>
          <div className="">
            <img src={thanksImage} alt='bedankt voor uw reservering' title='welkom bij DDV Verhuur' style={{ maxWidth: '100%', borderRadius: '10px' }}></img>
          </div>
        </div>
        <Map />
      </div>
      <PurchaseDatalayer reservationData={reservationData} />
    </Layout>
  )
}

export default ThankYou