import { Container } from '@mui/material';
import Logo from '../../images/svg/logo.webp';
import { Link } from 'gatsby';

const FooterNav = () => {
  return (
    <footer>
      <div className="footer-bg">
        <Container>
          <div className="footer-wrapper">
            <div className="footer-left">
              <div>
                <img src={Logo} alt="Logo DDV Verhuur" width={200}></img>
              </div>
              <div style={{ alignSelf: 'center' }}>
                Pittemstraat 153 <br></br>
                8760 Meulebeke, België
              </div>
            </div>
            <div className="footer-contact">
              Telefoon: <a href="tel:+3251792735">+32 51 79 27 35</a>
              <br></br>
              Email:{' '}
              <a href="mailto:info@ddv-verhuur.be">info@ddv-verhuur.be</a>
            </div>
            <div className="footer-opening">
              Ma - Vr: 10:00 - 18:00 <br></br>
              Za - Zo: Enkel op Afspraak
            </div>
          </div>
        </Container>
      </div>
      <div>
        <Container>
          <div className="footer-wrapper-gebruiksvoorwaarden">
            <div>
              <Link
                to={`/${'gebruiksvoorwaarden'}`}
                key={'gebruiksvoorwaarden'}
              >
                Gebruiksvoorwaarden en privacybeleid
              </Link>
            </div>
            <div>
              Powered by{' '}
              <a href="https://www.rentvisie.nl" style={{ fontWeight: 600 }}>
                Rentvisie - Verhuursoftware oplossingen
              </a>
            </div>
          </div>
        </Container>
      </div>
    </footer>
  );
};

export default FooterNav;
