import React from 'react';
import logo from '../../../images/svg/logo.webp';
import { Link } from 'gatsby';

const Step = ({ step, active, beforeActive, isLast }) => (
  <div className="reservation-header__step">
    {beforeActive ? (
      <span>{step.title}</span>
    ) : (
      <span className={active ? 'bold' : ''}>{step.title}</span>
    )}
    {!isLast && <div>{'>'}</div>}
  </div>
);

const RESERVATION_STEPS = [
  { title: 'Selecteer' },
  { title: `Extra's` },
  { title: 'Bevestigen' },
];

const ReservationHeader = ({ activeStepIndex, isCar }) => {
  const steps = RESERVATION_STEPS;
  return (
    <div className="reservation-header">
      <div className="reservation-header__left">
        {isCar ? (
          <>
            {' '}
            <Link to="/">
              <img
                src={logo}
                alt="logo DDV"
                className="navbar-logo"
                width={200}
                height={50}
              />
            </Link>
          </>
        ) : (
          <Link to="/machine-verhuur">
            <img
              src={logo}
              alt="logo DDV"
              className="navbar-logo"
              width={200}
              height={50}
            />
          </Link>
        )}
        {/* <Link to="/">
            <img
              src={logo}
              alt="logo DDV"
              className="navbar-logo"
              width={200}
              height={50}
            />
          </Link> */}
      </div>
      <div className="reservation-header__right">
        <div className="bottom-steps">
          <div className={`inner step${activeStepIndex}`}></div>
        </div>
        <div className="reservation-header__steps">
          {steps.map((step, index) => (
            <Step
              step={step}
              beforeActive={index < activeStepIndex - 1}
              active={index === activeStepIndex - 1}
              isLast={index === steps.length - 1}
              key={index}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ReservationHeader;
