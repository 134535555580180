import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  startDatez: null,
  endDatez: null,
  location: null,
};

const datelocationSlice = createSlice({
  name: 'datelocation',
  initialState,
  reducers: {
    setStartDatez: (state, action) => {
      state.startDatez = action.payload;
    },
    setEndDatez: (state, action) => {
      state.endDatez = action.payload;
    },
    setLocation: (state, action) => {
      state.location = action.payload;
    },
  },
});

export const { setStartDatez, setEndDatez, setLocation } =
  datelocationSlice.actions;
export default datelocationSlice.reducer;
