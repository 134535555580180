import React, { useState } from 'react';
import { Container } from '@mui/system';
import ReservationHeader from './shared/reservation-header';
import { navigate } from 'gatsby';
import CtaForm from '../shared/machines-cta-form/machinesform';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import {
  calculateBasePrice,
  getDifferenceInDays,
  parseDate,
} from '../../utils/helper-functions';
import SearchImageCarousel from '../reservation/product-carousel-search';

const ReservationSelectVehicle = ({ availableRentals, searchArgs }) => {
  const [showForm, setShowForm] = useState(false);

  const group = searchArgs.groupId;
  const pickupLocation = searchArgs.location;
  const pickupDate = searchArgs.pickupDate;
  const pickupTime = searchArgs.pickupTime;
  const returnDate = searchArgs.dropOffDate;
  const returnTime = searchArgs.dropOffTime;

  const includeVat = useSelector((state: RootState) => state.vat.includeVat);

  const startDate = parseDate(pickupDate);
  const endDate = parseDate(returnDate);

  const totalDays = getDifferenceInDays(startDate, endDate) + 1;

  const handleClickProduct = (id: number) =>
    navigate(
      `/huren/offerte-stap2?id=${id}&pickupDate=${pickupDate}&pickupTime=${pickupTime}&returnDate=${returnDate}&returnTime=${returnTime}&pickupLocation=${pickupLocation}&groupId=${group}`
    );

  return (
    <Container style={{ paddingBottom: 50 }}>
      {/* Check for isCar prop => send to resHeader => conditional logic on click logo */}
      <ReservationHeader
        activeStepIndex={1}
        isCar={availableRentals[0].isCar}
      />
      {showForm && (
        <CtaForm
          group={group}
          location={pickupLocation}
          pickupDate={pickupDate}
          pickupTime={pickupTime}
          returnDate={returnDate}
          returnTime={returnTime}
        ></CtaForm>
      )}
      <div className="reservation__title">
        <h2>
          Beschikbare{' '}
          {availableRentals.isCar && availableRentals.isCar === true
            ? 'Voertuigen'
            : 'Machines'}
        </h2>
      </div>
      <div
        style={{
          display: 'flex',
          gap: '1rem',
          alignItems: 'center',
          marginBottom: 5,
        }}
      >
        <button onClick={() => setShowForm(!showForm)}>
          {showForm ? 'Zoeken Inklappen' : 'Zoekopdracht aanpassen'}
        </button>
      </div>
      <div className="reservation-select-wrapper">
        <div>
          <div className="cards__grid">
            {availableRentals.map((availableRental: any) => (
              <div
                className="card"
                key={availableRental.id}
              >
                <div className="card__content">
                  <div className="card__title-wrapper">
                    <div className="card__title">
                      <h3>{availableRental.title}</h3>
                    </div>
                    <div></div>
                  </div>
                  <SearchImageCarousel assets={availableRental.mediaslider} isStrapi={true} />
                  {/* <div className="card__image">
                    <img
                      src={availableRental.mediaslider[0].url}
                      alt={availableRental.description}
                      style={{ maxWidth: '100%' }}
                    />
                  </div> */}
                  <div className="card__features" onClick={() => handleClickProduct(availableRental.id)}>
                    {availableRental.tags.map((tag) => (
                      <div className="card__feature" key={tag.naam}>
                        {tag.naam}
                      </div>
                    ))}
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      marginBottom: '20px',
                    }}
                  >
                    <p style={{ marginRight: '10px' }}> Totaal:</p>
                    <p>
                      €{' '}
                      {calculateBasePrice(
                        availableRental.prices,
                        totalDays,
                        includeVat
                      )}
                    </p>
                  </div>
                </div>

                <div className="card__more-info" onClick={() => handleClickProduct(availableRental.id)}>Selecteer</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Container>
  );
};

export default ReservationSelectVehicle;
