import { Container } from '@mui/material';
import CtaForm from './shared/machines-cta-form/machinesform';

const props = {};

export const HeroHome = () => (
  <div className="hero__background_m">
    <Container className="relative">
      <div className="hero__cta">
        <CtaForm props={props}></CtaForm>
      </div>
    </Container>
  </div>
);
