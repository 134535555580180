export const getDifferenceInDays = (date1, date2) => {
  const diffInMs = Math.abs(date2 - date1);
  return Math.ceil(diffInMs / (1000 * 60 * 60 * 24));
};

export const parseDate = (dateString) => {
  const realDate = dateString.split(' ')[0];
  const [day, month, year] = realDate.split('-').map(Number);
  return new Date(year, month - 1, day);
};

interface Price {
  id: number;
  huurperiode: string;
  vrijekilometers: string;
  prijs: number;
}

export const calculateBasePrice = (
  prices: Price[],
  days: number,
  includeVat: string
) => {
  let priceType: Price;
  const totalDays: number = days;

  let vatFactor = 1;
  if (includeVat === 'no') {
    vatFactor = 1.21;
  }

  if (days >= 3 && days < 7) {
    priceType = prices.find(
      (price: Price) => price.huurperiode.toLowerCase() === 'drie tot zes dagen'
    );
  } else if (days >= 7 && days < 14) {
    priceType = prices.find(
      (price: Price) => price.huurperiode.toLowerCase() === 'week'
    );
  } else if (days >= 14 && days <= 30) {
    priceType = prices.find(
      (price: Price) => price.huurperiode.toLowerCase() === 'tweeweken'
    );
  } else if (days >= 31 && days <= 60) {
    priceType = prices.find(
      (price: Price) => price.huurperiode.toLowerCase() === 'maand'
    );
  } else {
    priceType = prices.find(
      (price: Price) => price.huurperiode.toLowerCase() === 'enkele dag'
    );
  }

  if (!priceType) {
    return 'Prijs niet gevonden';
  }

  return ((priceType.prijs / vatFactor) * totalDays)
    .toFixed(2)
    .toString()
    .replace('.', ',');
};

export interface Extra {
  id: number;
  name: string;
  prijs: number;
  category?: string;
  maxPrijs?: number;
  eenmaligePrijs?: number;
  image?: any;
}

export const calculateExtraPrice = (
  extra: Extra,
  days: number,
  includeVat: string
) => {
  if (includeVat === 'yes') {
    return (extra.prijs * days).toFixed(2).replace('.', ',');
  } else {
    return ((extra.prijs * days) / 1.21).toFixed(2).replace('.', ',');
  }
};

export const calculateTotalPrice = (
  basePrice: string,
  extraPrice: number[],
  includeVat: string,
  days: number
) => {
  const extra = extraPrice.reduce((a, b) => a + b, 0);
  if (includeVat === 'yes') {
    return (parseFloat(basePrice) + extra * days).toFixed(2).replace('.', ',');
  } else {
    if (parseFloat(basePrice) > 0) {
      return (parseFloat(basePrice.replace(',', '.')) + (extra * days) / 1.21)
        .toFixed(2)
        .replace('.', ',');
    }
  }
};

// write a function to calculate VAT, note: singlePrice, maxPrice, priceDay

// export const calculateExtraPrice = (extra: {}, days, includeVat )
// if (singlePrice => return singlePrice)
// if (prijs => return price * days)
// if (prijs > maxPrijs => return maxPrijs)
