import { useState, useEffect } from 'react';
import { Rentvisie } from '@bakkie/ratality';

declare global {
  interface Window {
    dataLayer: any;
  }
}
const PurchaseDatalayer = ({ reservationData }) => {
  const dataLayer = [];
  useEffect(() => {
    dataLayer.push({ ecommerce: null });
    if (reservationData.rentals) {
      dataLayer.push({
        event: `purchase`,
        ecommerce: {
          items: [
            {
              item_name: `${reservationData.rentals[0].vehicleClass.description}`,
              item_id: `${reservationData.rentals[0].vehicleClass.vehicleClassId}`,
              price: `${reservationData.reservationTotal.value}`,
              price_excl_vat: `${
                ((reservationData.reservationTotal.value / 1.21) * 100) / 100
              }`,
              currency: 'EUR',
              item_category: `${reservationData.rentals[0].vehicleClass.category.categoryId}`,
              quantity: 1,
              pick_up_location_id: `${reservationData.rentals[0].pickupLocation.locationId}`,
              pick_up_location: `${reservationData.rentals[0].pickupLocation.town}`,
            },
          ],
          value: `${reservationData.reservationTotal.value}`,
          tax: `${(reservationData.reservationTotal.value * 0.21 * 100) / 100}`,
          currency: 'EUR',
          transaction_id: `${reservationData.reservationReference}`,
        },
      });
    }
  }, [reservationData]);
  return <h2 style={{ marginTop: 20 }}>Tot snel!</h2>;
};

export default PurchaseDatalayer;
