import { Link } from 'gatsby';
import Bestelwagen from '../../images/svg/icons8-van.png';
import Personenwagen from '../../images/svg/personenwagen.svg';
import Luxewagen from '../../images/svg/luxewagen.svg';
import Minibus from '../../images/svg/minibus.png';
import Koelwagen from '../../images/svg/flaticon-freeze-truck.png';
import Settings from '../settings.json';
const CategoryLinks = ({ groupId }) => {
  const mapIdToClassArray = Settings.categories.filter(
    (classes) => classes.id === groupId
  );

  return (
    <div className="cta-categories" id="cta-categories">
      <Link to="/bestelwagen-huren" className="cta-category">
        <div
          className={`${
            mapIdToClassArray.length > 0
              ? mapIdToClassArray[0].name === 'bestelwagen' && 'active-icon'
              : ''
          }`}
        >
          <img className="ddv-icon" src={Bestelwagen} alt="bestelwagens"></img>
          Bestelwagens
        </div>
      </Link>

      <Link to="/personenwagen-huren" className="cta-category">
        <div
          className={`${
            mapIdToClassArray.length > 0
              ? mapIdToClassArray[0].name === 'personenwagen' && 'active-icon'
              : ''
          }`}
        >
          <img
            className="ddv-icon"
            src={Personenwagen}
            alt="personenwagens"
          ></img>
          Personenwagens
        </div>
      </Link>

      <Link to="/luxewagen-huren" className="cta-category">
        <div
          className={`${
            mapIdToClassArray.length > 0
              ? mapIdToClassArray[0].name === 'luxewagen' && 'active-icon'
              : ''
          }`}
        >
          <img className="ddv-icon" src={Luxewagen} alt="luxewagens"></img>
          Luxewagens
        </div>
      </Link>

      <Link to="/minibus-huren" className="cta-category">
        <div
          className={`${
            mapIdToClassArray.length > 0
              ? mapIdToClassArray[0].name === 'minibus' && 'active-icon'
              : ''
          }`}
        >
          <img className="ddv-icon" src={Minibus} alt="minibussen"></img>
          Minibus
        </div>
      </Link>

      <Link to="/koelwagen-huren" className="cta-category">
        <div
          className={`${
            mapIdToClassArray.length > 0
              ? mapIdToClassArray[0].name === 'koelwagen' && 'active-icon'
              : ''
          }`}
        >
          <img className="ddv-icon" src={Koelwagen} alt="koelwagens"></img>
          Koelwagens
        </div>
      </Link>
      <Link to="/verhuiswagen-huren" className="cta-category">
        <div
          className={`${
            mapIdToClassArray.length > 0
              ? mapIdToClassArray[0].name === 'verhuiswagen' && 'active-icon'
              : ''
          }`}
        >
          <img className="ddv-icon" src={Bestelwagen} alt="bestelwagens"></img>
          Verhuiswagens
        </div>
      </Link>
    </div>
  );
};

export default CategoryLinks;
