import { Container } from "@mui/material"
import Layout from "../../components/layout"
import CtaForm from "../../components/shared/cta-form/form"


const BookNow = () => (
    <Layout>
        <Container style={{marginTop: 50}}>
            <h1>Huur direct bij DDV Autoverhuur</h1>
            <p>Kies een categorie, vul de locatie en ophaal-tijden in en wij tonen u het daadwerkelijk beschikbare aanbod.</p>
            <CtaForm />
        </Container>
    </Layout>
)

export default BookNow