import React from 'react';
import { OurFleet, StaticFleet } from '../components/fleet';
import { AdditionalFleet } from '../components/additionalFleet';
import { HeroHome } from '../components/hero';
import Layout from '../components/layout';
import { LuxuryCars } from '../components/luxury';
import { Usps } from '../components/Usps';
import SEO from '../components/seo';

export function Index() {
  return (
    <Layout>
      <SEO
        title="DDV Verhuur - Huur uw voertuigen"
        description="Huur uw auto of bestelwagen bij DDV Verhuur in Oostende en Meulbeke"
        follow={true}
        index={true}
      ></SEO>

      <HeroHome />
      <OurFleet />
      <StaticFleet />
      <LuxuryCars />
      <Usps />
    </Layout>
  );
}

export default Index;
