import React, { useEffect, useRef, useState } from 'react';
import ReservationHeader from './shared/reservation-header';
import { Rentvisie, Reservation } from '@bakkie/ratality';
import { navigate } from 'gatsby';
import { ValidatorUtil } from './validator.util';
import { Container } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import Terms from './Terms';

declare global {
  interface Window {
    dataLayer: any;
  }
}

Rentvisie.setConfig({
  baseUrl: 'https://rentvisie-proxy-aws.herokuapp.com/api',
  client: 'ddv',
  useGuestToken: true,
});

export interface ReservationCheckoutProps {
  // TODO: Add mileagePackageOptions
  reservationData: Reservation;
  query: any;
}

const ReservationCheckout = ({
  reservationData,
  query,
}: ReservationCheckoutProps) => {
  const [showLoading, setShowLoading] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);
  let includeVat = 'no';
  if (typeof window !== 'undefined') {
    includeVat = localStorage.getItem('includeVat');
  }

  const reservationReference = reservationData.reservationReference;
  const bookingReference = reservationData.rentals[0].bookingReference;
  const {
    pickupLocation,
    pickupDate,
    pickupTime,
    dropOffDate,
    dropOffTime,
    vehicleClass,
  } = reservationData.rentals[0];
  const kassaKoopje = vehicleClass.optionalOptions.filter(
    (option) => option.category === 'Kassakoopje'
  );

  const rentalHasInsuranceOptions =
    ValidatorUtil.validateRentalInsuranceOptions(
      reservationData.rentals[0].insurances[0]
    );
  const rentalHasMileageOptions = ValidatorUtil.validateRentalMileageOptions(
    reservationData.rentals[0].mileagePackages[0]
  );
  const rentalHasOptionalOptions = ValidatorUtil.validateRentalOptionalOptions(
    reservationData.rentals[0].options
  );

  const insuranceOptionsData = [];
  if (rentalHasInsuranceOptions) {
    insuranceOptionsData.push(reservationData.rentals[0].insurances[0]);
  }
  const mileagePackageOptionsData = [];
  if (rentalHasMileageOptions) {
    mileagePackageOptionsData.push(
      reservationData.rentals[0].mileagePackages[0]
    );
  }
  const optionalOptionsData = reservationData.rentals[0].options;
  const [postalcodeData, setPostalcodeData] = useState(undefined);

  const [customerData, setCustomerData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    dateOfBirth: '',
  });

  const [physicalAddress, setPhysicalAddress] = useState({
    houseNumber: '',
    houseNumberAdditional: '',
    streetName: '',
    city: '',
    postalCode: '',
    countryCode: 'BE',
  });

  const [cellphoneNumber, setcellphoneNumber] = useState({
    countryDialingCode: '32',
    number: '',
  });

  const [companyData, setCompanyData] = useState({
    name: '',
    vatNumber: '',
  });
  const [newCustomerData, setNewCustomerData] = useState({});

  //Validations
  const hasValidBusinessName = ValidatorUtil.validateName(companyData.name);
  const hasValidVatNumber = ValidatorUtil.validateVatNumber(
    companyData.vatNumber
  );
  const hasValidFirstName = ValidatorUtil.validateName(customerData.firstName);
  const hasValidLastName = ValidatorUtil.validateName(customerData.lastName);
  const hasValidPhone = ValidatorUtil.validatePhone(cellphoneNumber.number);
  const hasValidEmail = ValidatorUtil.validateEmail(customerData.email);
  const hasValidZipCode = ValidatorUtil.validateZipCode(
    physicalAddress.postalCode
  );
  const hasValidHouseNumber = ValidatorUtil.validateHouseNumber(
    physicalAddress.houseNumber
  );
  const hasValidAddition = ValidatorUtil.validateAddition(
    physicalAddress.houseNumberAdditional
  );
  const hasValidStreet = ValidatorUtil.validateStreet(
    physicalAddress.streetName
  );
  const isValidCustomer =
    ValidatorUtil.validateCustomer(
      hasValidFirstName,
      hasValidLastName,
      hasValidPhone,
      hasValidEmail,
      hasValidZipCode,
      hasValidHouseNumber,
      hasValidStreet
    ) && termsAccepted;
  const hasValidKassakoopje = ValidatorUtil.validateKassakoopje(kassaKoopje);

  const [pushedDataLayer, setPushedDataLayer] = useState(false);
  const dataLayer = window.dataLayer || [];
  if (!pushedDataLayer) {
    dataLayer.push({ ecommerce: null });
    dataLayer.push({
      event: `add_payment_information`,
      ecommerce: {
        currency: 'EUR',
        value: `${reservationData.reservationTotal.value}`,
        tax: `${(reservationData.reservationTotal.value * 0.21 * 100) / 100}`,
        payment_type: 'Pay Later',
        items: [
          {
            item_name: `${reservationData.rentals[0].vehicleClass.description}`,
            item_id: `${reservationData.rentals[0].vehicleClass.vehicleClassId}`,
            price: `${reservationData.reservationTotal.value}`,
            price_excl_vat: `${
              ((reservationData.reservationTotal.value / 1.21) * 100) / 100
            }`,
            currency: 'EUR',
            item_category: `${reservationData.rentals[0].vehicleClass.category.categoryId}`,
            quantity: 1,
            pick_up_location_id: `${reservationData.rentals[0].pickupLocation.locationId}`,
            pick_up_location: `${reservationData.rentals[0].pickupLocation.town}`,
          },
        ],
      },
    });
    setPushedDataLayer(true);
  }

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const itemStr = localStorage.getItem('returnToCheckout');
      // if the item doesn't exist, return null
      if (!itemStr) {
        localStorage.removeItem('returnToCheckout');
      }

      const item = {
        reservationReference: reservationReference,
        expiry: reservationData.expiryDate,
      };
      localStorage.setItem('returnToCheckout', JSON.stringify(item));
    }
  });

  const customerDataChanged = useRef(false);

  const handleChange = (e) => {
    setCustomerData({
      ...customerData,
      [e.target.name]: e.target.value,
    });
  };

  const handleChangeCompany = (e) => {
    setCompanyData({
      ...companyData,
      [e.target.name]: e.target.value,
    });
  };

  const handleChangePhysicalAddress = (e) => {
    setPhysicalAddress({
      ...physicalAddress,
      [e.target.name]: e.target.value,
    });
    customerDataChanged.current = !customerDataChanged.current;
  };

  const handleChangePhone = (e) => {
    setcellphoneNumber({
      ...cellphoneNumber,
      [e.target.name]: e.target.value,
    });
  };

  const handleChangeTerms = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTermsAccepted(event.target.checked);
  };

  // TODO: Move to updateReservation
  useEffect(() => {
    setNewCustomerData({
      ...customerData,
      physicalAddress: {
        ...physicalAddress,
      },
      company: {
        ...companyData,
      },
      cellphoneNumber: {
        ...cellphoneNumber,
      },
    });
  }, [customerData, physicalAddress, companyData, cellphoneNumber]);

  if (typeof window !== 'undefined') {
    useEffect(() => {
      if (newCustomerData !== {}) {
        localStorage.setItem(
          'storeCustomerData',
          JSON.stringify(newCustomerData)
        );
      }
    }, [newCustomerData]);
  }

  const [isCompany, setIsCompany] = useState(false);
  const customerType = (e) => {
    if (e.target.value == 'company') {
      setIsCompany(true);
    } else {
      setIsCompany(false);
    }
  };

  const [isCheckoutDeal, setIsCheckoutDeal] = useState(undefined);
  const [checkoutValue, setCheckoutValue] = useState(0);
  const handleCheckoutDeal = (e) => {
    if (e.target.checked) {
      setIsCheckoutDeal(kassaKoopje[0].optionId);
      setCheckoutValue(e.target.value);
    } else {
      setIsCheckoutDeal(undefined);
      setCheckoutValue(0);
    }
  };
  const totalDays = reservationData.rentals[0].days;
  // Update Price
  const getPrice = (basePrice, checkOutValue): number => {
    return (
      Math.round((parseFloat(basePrice) + parseFloat(checkOutValue)) * 100) /
      100
    );
  };
  const getPriceOption = (price, type) => {
    if (type === 'PerDay') {
      return price * totalDays;
    }
    return price;
  };

  // Update Reservation && Redirect Booking to Thank You Page
  const updateReservation = async (newCustomerData, isCheckoutDeal) => {
    try {
      await setShowLoading(true);
      await Rentvisie.addCustomerData(
        reservationReference,
        bookingReference,
        newCustomerData
      );
      await Rentvisie.confirmationEmail(reservationReference, bookingReference);
      if (isCheckoutDeal) {
        Rentvisie.addOptionalOption(
          reservationReference,
          bookingReference,
          isCheckoutDeal
        );
      }
      if (typeof window !== 'undefined') {
        await localStorage.setItem(
          'reservationReference',
          reservationReference
        );
        // await localStorage.setItem('bookingReference', bookingReference);
        // await localStorage.setItem('storedCustomerData', JSON.stringify(newCustomerData));
        const itemStr = await localStorage.getItem('returnToCheckout');
        if (!itemStr) {
          return null;
        }
        await localStorage.removeItem('returnToCheckout');
      }
      const url = `/huren/bedankt?reservationReference=${reservationReference}`;
      navigate(url);
    } catch (e) {}
  };

  const expiryDate = new Date(reservationData.expiryDate).getTime();
  const calculateTimeLeft = () => {
    const now = new Date().getTime();
    const difference = expiryDate - now;
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        minuten: Math.floor((difference / 1000 / 60) % 60),
        seconden: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
  });

  const redirect = () => {
    window.location.replace('/');
  };

  if (reservationData.reservationStatus !== 'Draft') {
    navigate('/');
  }

  const [showSummary, setShowSummary] = useState(false);
  return (
    <Container>
      <ReservationHeader activeStepIndex={3} isCar={true} />
      <div className="reservation-select-wrapper my-l">
        {showLoading ? (
          <h3>
            Ogenblik geduld, wij kloppen uw reservering nu in het systeem.
          </h3>
        ) : (
          <>
            <div className="reservation-select-left">
              <div className="checkout-wrapper">
                <div className="reservation__title">
                  <h2>Maak jouw reservering compleet</h2>
                  <p style={{ fontStyle: 'italic' }}>
                    <span className="bold">Let op:</span> U heeft nog{' '}
                    {timeLeft.minuten + timeLeft.seconden > 0
                      ? timeLeft.minuten > 0
                        ? `${timeLeft.minuten} minuten en ${timeLeft.seconden} seconden`
                        : `${timeLeft.seconden} seconden`
                      : redirect()}{' '}
                    om de reservering te voltooien. Daarna dient u het
                    reserveringsproces opnieuw te doorlopen.
                  </p>
                </div>
                {includeVat !== 'yes' && (
                  <>
                    <div className="form-row">
                      <div className="form-100">
                        <div
                          className={`textOnInput ${
                            hasValidBusinessName && 'valid'
                          } ${
                            companyData.name &&
                            !hasValidBusinessName &&
                            'not-valid'
                          }`}
                        >
                          <label htmlFor="name">Bedrijfsnaam</label>
                          <input
                            type="text"
                            name="name"
                            onChange={handleChangeCompany}
                            className="form-input-alt"
                          ></input>
                        </div>
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-100">
                        <div
                          className={`textOnInput ${
                            hasValidVatNumber && 'valid'
                          } ${
                            companyData.vatNumber &&
                            !hasValidVatNumber &&
                            'not-valid'
                          }`}
                        >
                          <label htmlFor="vatNumber">BTW-nummer</label>
                          <input
                            type="text"
                            name="vatNumber"
                            onChange={handleChangeCompany}
                            className="form-input-alt"
                          ></input>
                        </div>
                      </div>
                    </div>
                  </>
                )}
                <div className="form-row">
                  <div className="form-50">
                    <div
                      className={`textOnInput ${hasValidFirstName && 'valid'} ${
                        customerData.firstName &&
                        !hasValidFirstName &&
                        'not-valid'
                      }`}
                    >
                      <label htmlFor="firstName">Voornaam</label>
                      <input
                        type="text"
                        name="firstName"
                        onChange={handleChange}
                        className="form-input-alt"
                      ></input>
                    </div>
                  </div>
                  <div className="form-50">
                    <div
                      className={`textOnInput ${hasValidLastName && 'valid'} ${
                        customerData.lastName &&
                        !hasValidLastName &&
                        'not-valid'
                      }`}
                    >
                      <label htmlFor="lastName">Achternaam</label>
                      <input
                        type="text"
                        name="lastName"
                        onChange={handleChange}
                        className="form-input-alt"
                      ></input>
                    </div>
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-100">
                    <div
                      className={`textOnInput ${hasValidPhone && 'valid'} ${
                        cellphoneNumber.number && !hasValidPhone && 'not-valid'
                      }`}
                    >
                      <label htmlFor="number">Telefoon</label>
                      <input
                        type="phone"
                        name="number"
                        onChange={handleChangePhone}
                        className="form-input-alt"
                      ></input>
                    </div>
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-100">
                    <div
                      className={`textOnInput ${hasValidEmail && 'valid'} ${
                        customerData.email && !hasValidEmail && 'not-valid'
                      }`}
                    >
                      <label htmlFor="email">Email</label>
                      <input
                        type="email"
                        name="email"
                        onChange={handleChange}
                        className={'form-input-alt'}
                      ></input>
                    </div>
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-33">
                    <div
                      className={`textOnInput ${hasValidZipCode && 'valid'} ${
                        physicalAddress.postalCode &&
                        !hasValidZipCode &&
                        'not-valid'
                      }`}
                    >
                      <label htmlFor="postalCode">Postcode</label>
                      <input
                        type="text"
                        name="postalCode"
                        className="form-input-alt"
                        id="postalCode"
                        maxLength={6}
                        onChange={handleChangePhysicalAddress}
                      ></input>
                    </div>
                  </div>
                  <div className="form-33">
                    <div
                      className={`textOnInput ${
                        hasValidHouseNumber && 'valid'
                      } ${
                        physicalAddress.houseNumber &&
                        !hasValidHouseNumber &&
                        'not-valid'
                      }`}
                    >
                      <label htmlFor="houseNumber">Huisnummer</label>
                      <input
                        type="text"
                        name="houseNumber"
                        className="form-input-alt"
                        id="houseNumber"
                        maxLength={6}
                        onBlur={handleChangePhysicalAddress}
                      ></input>
                    </div>
                  </div>
                  <div className="form-33">
                    <div
                      className={`textOnInput ${hasValidAddition && 'valid'} ${
                        physicalAddress.houseNumberAdditional &&
                        !hasValidAddition &&
                        'not-valid'
                      }`}
                    >
                      <label htmlFor="houseNumberAdditional">Toevoeging</label>
                      <input
                        type="text"
                        name="houseNumberAdditional"
                        onChange={handleChangePhysicalAddress}
                        className="form-input-alt"
                      ></input>
                    </div>
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-100">
                    <div
                      className={`textOnInput ${hasValidStreet && 'valid'} ${
                        physicalAddress.streetName &&
                        !hasValidStreet &&
                        'not-valid'
                      }`}
                    >
                      <label htmlFor="streetName">Straat</label>
                      <input
                        type="text"
                        className="form-input-alt"
                        onChange={handleChangePhysicalAddress}
                        name="streetName"
                      ></input>
                    </div>
                  </div>
                </div>
                <Checkbox
                  checked={termsAccepted}
                  onChange={handleChangeTerms}
                  inputProps={{ 'aria-label': 'controlled' }}
                />{' '}
                <Terms />
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginTop: 10,
                  }}
                >
                  <div>
                    <button onClick={() => setShowSummary(!showSummary)}>
                      {showSummary ? 'Minimaliseer' : 'Toon Winkelmandje'}
                    </button>
                  </div>
                  <div>
                    {isValidCustomer ? (
                      <button
                        className="primary-button"
                        onClick={() =>
                          updateReservation(newCustomerData, isCheckoutDeal)
                        }
                      >
                        Reserveren
                      </button>
                    ) : (
                      <button disabled>Vul de gevraagde gegevens in</button>
                    )}
                  </div>
                </div>
                {showSummary && (
                  <>
                    {hasValidKassakoopje && (
                      <div className="checkout-deal-wrapper">
                        <div className="checkout-extra">
                          <h2>Kassakoopje!</h2>
                          <input
                            type="checkbox"
                            id="kassakoopje"
                            name="kassakoopje"
                            value={kassaKoopje[0].price.value}
                            onChange={handleCheckoutDeal}
                          />
                          <label htmlFor="kassakoopje">
                            <div className="extrabox">
                              <h3>
                                {kassaKoopje[0].description} (€{' '}
                                {((kassaKoopje[0].price.value * 100) / 100)
                                  .toFixed(2)
                                  .toString()
                                  .replace('.', ',')}
                                )
                              </h3>
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: kassaKoopje[0].labelMarkup,
                                }}
                              />
                            </div>
                          </label>
                        </div>
                      </div>
                    )}
                    {/* TODO: Add Payment */}
                    {/* <div className="payment"><h2>Betaling</h2></div> */}
                    <div className="shopping-cart" style={{ marginTop: 20 }}>
                      <h2>Winkelmandje</h2>
                      <span style={{ fontSize: 10 }}>
                        Aantal dag(en): {totalDays}
                      </span>
                      <div className="shopping-cart-wrapper">
                        <div className="shopping-cart-category">
                          <span className="bold">Voertuig</span>
                          <hr></hr>
                          <div className="shopping-cart-row">
                            <div className="shopping-cart-left">
                              {reservationData.rentals[0].vehicleClass
                                .labelMarkup
                                ? reservationData.rentals[0].vehicleClass.labelMarkup?.replace(
                                    /(<([^>]+)>)/gi,
                                    ''
                                  )
                                : reservationData.rentals[0].vehicleClass
                                    .description}
                            </div>
                            <div className="shopping-cart-right">
                              €{' '}
                              {(
                                (reservationData.rentals[0].price.value *
                                  totalDays) /
                                1.21
                              )
                                .toFixed(2)
                                .toString()
                                .replace('.', ',')}
                            </div>
                          </div>
                        </div>
                        {rentalHasInsuranceOptions && (
                          <div className="shopping-cart-category">
                            <span className="bold">
                              Verzekering & Eigen Risico
                            </span>
                            <hr></hr>
                            <div className="shopping-cart-row">
                              <div className="shopping-cart-left">
                                {insuranceOptionsData[0].description}
                              </div>
                              <div className="shopping-cart-right">
                                €{' '}
                                {(
                                  getPriceOption(
                                    insuranceOptionsData[0].price.value,
                                    insuranceOptionsData[0].price.unitType
                                  ) / 1.21
                                )
                                  .toFixed(2)
                                  .toString()
                                  .replace('.', ',')}
                              </div>
                            </div>
                          </div>
                        )}
                        {rentalHasMileageOptions && (
                          <div className="shopping-cart-category">
                            <span className="bold">Kilometers</span>
                            <hr></hr>
                            <div className="shopping-cart-row">
                              <div className="shopping-cart-left">
                                {mileagePackageOptionsData[0].description}
                              </div>
                              <div className="shopping-cart-right">
                                €{' '}
                                {(
                                  getPriceOption(
                                    mileagePackageOptionsData[0].price.value,
                                    mileagePackageOptionsData[0].price.unitType
                                  ) / 1.21
                                )
                                  .toFixed(2)
                                  .toString()
                                  .replace('.', ',')}
                              </div>
                            </div>
                          </div>
                        )}
                        {rentalHasOptionalOptions && (
                          <div className="shopping-cart-category">
                            <span className="bold">Overige</span>
                            <hr></hr>
                            {optionalOptionsData.map((option) => (
                              <div
                                key={option.optionId}
                                className="shopping-cart-row"
                              >
                                <div className="shopping-cart-left">
                                  {option.description}
                                </div>
                                <div className="shopping-cart-right">
                                  €{' '}
                                  {(
                                    getPriceOption(
                                      option.price.value,
                                      option.price.unitType
                                    ) / 1.21
                                  )
                                    .toFixed(2)
                                    .toString()
                                    .replace('.', ',')}
                                </div>
                              </div>
                            ))}
                          </div>
                        )}
                        {/* TODO: CHECK FOR MULTIPLE DAYS */}
                        {isCheckoutDeal && (
                          <div className="shopping-cart-category">
                            <span className="bold">Kassakoopje!</span>
                            <hr></hr>
                            <div className="shopping-cart-row">
                              <div className="shopping-cart-left">
                                {kassaKoopje[0].description}
                              </div>
                              <div className="shopping-cart-right">
                                €{' '}
                                {(kassaKoopje[0].price.value / 1.21)
                                  .toFixed(2)
                                  .toString()
                                  .replace('.', ',')}
                              </div>
                            </div>
                          </div>
                        )}
                        <div className="shopping-cart-category">
                          <span className="bold">Totaal</span>
                          <hr></hr>
                          <div className="shopping-cart-row">
                            <div className="shopping-cart-left">
                              Totaal (excl.)
                            </div>
                            <div className="shopping-cart-right">
                              {`€ ${' '} ${(
                                getPrice(
                                  reservationData.reservationTotal.value,
                                  checkoutValue
                                ) / 1.21
                              )
                                .toFixed(2)
                                .toString()
                                .replace('.', ',')}`}
                            </div>
                          </div>
                          <div className="shopping-cart-row">
                            <div className="shopping-cart-left">BTW</div>
                            <div className="shopping-cart-right">
                              {`€ ${' '} ${(
                                (getPrice(
                                  reservationData.reservationTotal.value,
                                  checkoutValue
                                ) /
                                  1.21) *
                                0.21
                              )
                                .toFixed(2)
                                .toString()
                                .replace('.', ',')}`}
                            </div>
                          </div>
                          {includeVat === 'yes' && (
                            <div className="shopping-cart-row bold">
                              <div className="shopping-cart-left">
                                Totaal (incl.)
                              </div>
                              <div className="shopping-cart-right">
                                {`€ ${' '} ${getPrice(
                                  reservationData.reservationTotal.value,
                                  checkoutValue
                                )
                                  .toFixed(2)
                                  .toString()
                                  .replace('.', ',')}`}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="reservation-select-right">
              <div className="reservation-contact">
                <div className="reservation-product__photo">
                  <img
                    src={vehicleClass.images[0].imageUrl}
                    className="img-fluid rounded"
                    alt={vehicleClass.images[0].fileName}
                    title={vehicleClass.description}
                  />
                </div>
                <div className="reservation-product__text">
                  <p>
                    <a
                      href={encodeURI(
                        `https://www.google.com/maps/search/?api=1&query=${pickupLocation.streetAddress}`
                      )}
                      target="_blank"
                    >
                      {pickupLocation.streetAddress}
                    </a>
                  </p>
                  <p>
                    Van: {pickupDate} om {pickupTime} <br></br>
                    Tot: {dropOffDate} om {dropOffTime} <br></br>
                  </p>
                  <h3 className="bold">
                    {reservationData.rentals[0].vehicleClass.labelMarkup
                      ? reservationData.rentals[0].vehicleClass.labelMarkup?.replace(
                          /(<([^>]+)>)/gi,
                          ''
                        )
                      : reservationData.rentals[0].vehicleClass.description}
                  </h3>
                  <p className="small-top">Nu voor slechts:</p>
                  <h3 className="extrabox__price">
                    {includeVat === 'yes'
                      ? `€ ${' '} ${(
                          (getPrice(
                            reservationData.reservationTotal.value,
                            checkoutValue
                          ) *
                            100) /
                          100
                        )
                          .toFixed(2)
                          .toString()
                          .replace('.', ',')}`
                      : `€ ${' '} ${(
                          getPrice(
                            reservationData.reservationTotal.value,
                            checkoutValue
                          ) / 1.21
                        )
                          .toFixed(2)
                          .toString()
                          .replace('.', ',')}`}
                    {includeVat === 'yes' ? ' incl. BTW' : ' excl. BTW'}
                  </h3>
                </div>
              </div>
              <div className="reservation-contact">
                <div className="reservation-product__text">
                  <h3>Na sluitingstijd inleveren?</h3>
                  <p>
                    Geen probleem! U kunt de sleutel deponeren in de
                    sleutelkluis.
                  </p>
                  <p className="small-top" style={{ fontWeight: 600 }}>
                    {includeVat === 'yes'
                      ? ' € 10 incl. BTW'
                      : ' € 8 excl. BTW'}
                  </p>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </Container>
  );
};

export default ReservationCheckout;
