import { Link } from 'gatsby';
import ReactMarkdown from 'react-markdown';
import { useSelector } from 'react-redux';
import { manipulatePriceForVat, manipulatePriceTagForVat } from '../utils/vatHelper';

const Fleetrow = ({ row }) => {
  let includeVat = useSelector((state) => state.vat.includeVat);

  return (
    <div className="row-50">
      <div className="fleet__image">
        <img
          src={row.image.url}
          alt={row.image.alternativeText}
          title={row.image.caption}
          key={row.strapi_id}
          style={{ width: '100%' }}
        />
        <div className="fleet__highlight">
          <div>
            <span style={{ fontWeight: 600, fontSize: 18 }}>
              Vanaf €{' '}
              {manipulatePriceForVat(row.vanaf, includeVat)}
              <br></br>
            </span>
            {manipulatePriceTagForVat(includeVat)}
          </div>
        </div>
      </div>
      <div>
        <h3>{row.naam}</h3>
        <ReactMarkdown>{row.body.data.body}</ReactMarkdown>
        <div style={{ marginTop: '1rem' }}>
          <Link className="link__arrow" title="Personenwagens" href={row.url}>
            {row.linkText}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Fleetrow;
