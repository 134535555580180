import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { Container } from "@mui/system"
import Typography from '@mui/material/Typography';
import Layout from "../components/layout";
import DownIcon from '../images/icons/chevron-down.png'
import { graphql } from "gatsby"
import ReactMarkdown from 'react-markdown';
import SEO from '../components/seo';
import "../styles/faq.scss"

export const query = graphql`
  {
    strapiFaq {
        seoBasic {
            seoTitle
            seoMetadescription
        }
      faqs {
        strapi_id
        vraag
        antwoord {
          data {
            antwoord
          }
        }
      }
      additionalFaqs {
        strapi_id
        vraag
        antwoord {
          data {
            antwoord
          }
        }
      }
      title
      body {
        data {
          body
        }
      }
    }
  }
`

const Faq = ({ data }) => {
  const faqData = data.strapiFaq
  return (
    <Layout>
      <SEO title={faqData.seoBasic.seoTitle} description={faqData.seoBasic.seoMetadescription}></SEO>
      <Container >
        <div className="faq-wrapper">
          <div className="faq-content">
            <div className='faq-image'>
            </div>
          </div>
          <div className="faq-content">
            <h1>{faqData.title}</h1>
            <ReactMarkdown>{faqData.body.data.body}</ReactMarkdown>
            <div style={{ marginTop: '2rem' }}>
              <h2>Algemene vragen</h2>
              {faqData.faqs.map((faq) => (
                <Accordion key={faq.strapi_id}>
                  <AccordionSummary
                    expandIcon={<img src={DownIcon} alt='arrow'></img>}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography style={{ fontWeight: 600 }}>{faq.vraag}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <ReactMarkdown>{faq.antwoord.data.antwoord}</ReactMarkdown>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              ))}
            </div>
            <div style={{ marginTop: '2rem' }}>
              <h2>Overige vragen</h2>
              {faqData.additionalFaqs.map((faq) => (
                <Accordion key={faq.strapi_id}>
                  <AccordionSummary
                    expandIcon={<img src={DownIcon} alt='arrow'></img>}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography style={{ fontWeight: 600 }}>{faq.vraag}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <ReactMarkdown>{faq.antwoord.data.antwoord}</ReactMarkdown>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              ))}
            </div>
          </div>
        </div>
        </Container>
    </Layout>
  )
}

export default Faq;