import Layout from '../components/layout';
import SEO from '../components/seo';
import { Link } from 'gatsby';
import { Container, Box } from '@mui/system';
import { Button } from '@mui/material';
import { useState, useEffect } from 'react';
import ProductImageCarousel from '../components/product-carousel';
import VehicleForm from '../components/modals/vehicleForm';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import { Divider } from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/store';
import { manipulatePriceForVat, manipulatePriceTagForVat } from '../utils/vatHelper';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

interface Product {
  pageContext: Queries.STRAPI_PRODUCT;
}

const Products = ({ pageContext }: Product) => {
  const { includeVat } = useSelector((state: RootState) => state.vat);

  const [open, setOpen] = useState(false);
  const [nonReal, setNonReal] = useState(false);

  const handleOpen = () => setOpen(true);

  // TODO: Readd datalayer for ecommerce

  const {
    title,
    seo,
    vehicleClassId,
    description,
    specifications,
    documenten,
    mediaslider,
    prices,
    // video,
    faqs,
    group,
    id,
    isCar,
  } = pageContext;
  console.log(pageContext);
  return (
    <Layout>
      <SEO
        title={seo?.metaTitle}
        description={seo?.metaDescription}
        image={seo?.metaImage?.url}
        index={true}
        follow={true}
      ></SEO>
      {/* // temp fix for migration */}
      {group && (
        <>
          <Container>
            {/* Breadcrumbs section */}
            <div className="breadcrumbs" style={{ alignSelf: 'start' }}>
              <div className="breadcrumb">
                {isCar ? (
                  <Link to="/">Autos</Link>
                ) : (
                  <Link to="/machine-verhuur">Machines</Link>
                )}
              </div>
              <div className="breadcrumb">
                {group?.slug && (
                  <Link to={`/${group.slug.toLowerCase()}`}>
                    {group.title.toLowerCase()}
                  </Link>
                )}
              </div>
              <div className="breadcrumb">{title.toLowerCase()}</div>
            </div>

            <h1 style={{ alignSelf: 'start' }}>{title}</h1>

            {/* Above Fold section */}
            <Box
              sx={{
                display: 'flex',
                flexDirection: { xs: 'column', md: 'row' },
                gap: 3,
              }}
            >
              <div
                style={{
                  flex: 1,
                }}
              >
                <ProductImageCarousel
                  assets={mediaslider.map((data) => ({
                    url: data.url,
                    description: data.name,
                    ext: data.ext,
                    id: data.strapi_id,
                  }))}
                ></ProductImageCarousel>
              </div>

              {/* Content section */}
              <div
                style={{
                  flex: 1,
                  background: 'white',
                  borderRadius: 10,
                }}
              >
                <div style={{ padding: 24 }}>
                  <ReactMarkdown>
                    {description?.data?.description}
                  </ReactMarkdown>

                  <Box style={{ gap: 12, display: 'flex' }}>
                    <button
                      className="button primary-button"
                      onClick={() => {
                        setNonReal(true);
                        handleOpen();
                      }}
                    >
                      Offerte Opvragen
                    </button>
                    {isCar && (
                      <button
                        className="button primary-button"
                        onClick={() => {
                          setNonReal(false);
                          handleOpen();
                        }}
                      >
                        Huur direct
                      </button>
                    )}
                  </Box>
                </div>
              </div>
            </Box>

            {/* PRICES SECTION */}
            <section>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-around',
                  gap: 12,
                }}
                className="product-pricing__wrapper"
              >
                <div>
                  <h3 className="product-pricing__description bg-primary">
                    Huur periode
                  </h3>

                  <div className="product-pricing__box">
                    {prices?.map((price) => (
                      <p style={{ alignSelf: 'start' }}>{price.huurperiode}</p>
                    ))}
                  </div>
                </div>

                <div>
                  <h3 className="product-pricing__description">Prijs/ dag</h3>

                  <div className="product-pricing__box">
                    {prices?.map((price) => (
                      <p style={{ alignSelf: 'center' }}>
                        € {manipulatePriceForVat(price.prijs, includeVat)} 
                        {manipulatePriceTagForVat(includeVat)}
                      </p>
                    ))}
                  </div>
                </div>
                <div>
                  <h3 className="product-pricing__description">
                    {isCar ? 'Vrije kilometers' : 'Vrije uren'}/ dag{' '}
                  </h3>
                  <div className="product-pricing__box">
                    {prices?.map((price) => (
                      <p style={{ alignSelf: 'center' }}>
                        {isCar ? price.vrijeKilometers : (price.inbegrepenUren && price.inbegrepenUren || '[nvt]')} 
                        {isCar ? ' kilometers' : ' uren'}
                      </p>
                    ))}
                  </div>
                </div>
              </div>
            </section>

            {/* VIDEO SECTION */}
            {/* TODO: Lazy load? */}

            {/* {video ? (
          <section>
              <video width="100%" controls>
                <source src={video.url} type="video/mp4" />
              </video>
          </section>
        ) : (
          <img src={mediaslider[0].url} alt={mediaslider.name} width={"100%"} />
        )} */}
            {specifications.length > 0 && documenten && (
              <div className="grid-50">
                {specifications?.length > 0 && (
                  <Box sx={{ flex: 1 }}>
                    <h2 style={{ alignSelf: 'center' }}>Kenmerken</h2>
                    {specifications?.map((spec) => (
                      <div
                        style={{
                          borderBottom: '1px solid black',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          padding: 10,
                        }}
                      >
                        <div style={{ fontWeight: 600 }}>{spec.label}</div>
                        <div>{spec.description}</div>
                      </div>
                    ))}
                  </Box>
                )}

                {documenten && (
                  <Box
                    sx={{
                      flex: 1,
                    }}
                  >
                    <Box
                      sx={{
                        background: '#fff',
                        borderRadius: 3,
                        padding: 3,
                      }}
                    >
                      <h2>{documenten.title}</h2>
                      {documenten?.pdf?.map((data) => (
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 3,
                            padding: '6px 0',
                          }}
                          key={data.strapi_id}
                        >
                          <PictureAsPdfIcon />
                          <a href={data.url} target="_blank" rel="noreferrer">
                            {data.name}
                          </a>
                        </Box>
                      ))}
                    </Box>
                  </Box>
                )}
              </div>
            )}
          </Container>
          <section>
            <div className="cta-break">
              <div className="cta-break__buttons">
                <button
                  style={{ width: '100%', background: 'transparent' }}
                  className="button primary-button"
                  onClick={() => {
                    setNonReal(true);
                    handleOpen();
                  }}
                >
                  Offerte Opvragen
                </button>

                {isCar && (
                  <button
                    className="button primary-button"
                    style={{ width: '100%', background: 'transparent' }}
                    onClick={() => {
                      setNonReal(false);
                      handleOpen();
                    }}
                  >
                    Huur real time
                  </button>
                )}
              </div>
            </div>
          </section>
          <Container>
            {/* FAQ Section */}
            <h2
              style={{
                alignSelf: 'center',
              }}
            >
              Veelgestelde vragen
            </h2>
            <Divider sx={{ borderColor: 'black' }} />
            {faqs?.map((faq) => (
              <div>
                <h3
                  style={{
                    alignSelf: 'center',
                  }}
                >
                  {faq.vraag}
                </h3>
                <p>{faq.antwoord.data.antwoord}</p>
                <Divider sx={{ borderColor: 'black' }} />
              </div>
            ))}
          </Container>
        </>
      )}

      <VehicleForm
        open={open}
        setOpen={setOpen}
        vehicleClassId={vehicleClassId}
        productid={id}
        nonReal={nonReal}
      />
    </Layout>
  );
};

export default Products;
