const VAT = 0.21;

export const manipulatePriceForVat = (price: number, includeVat: string) => {
    if (!price) { return 'Aanvraag';}

    if (includeVat === 'yes') {
        return price.toFixed(2).toString().replace(".", ",");
    }

    return Math.round(price / (1 + VAT)).toFixed(2).toString().replace(".", ",")
}

export const manipulatePriceTagForVat = (includeVat: string) => {
    if (includeVat === 'yes') {
        return " incl. BTW"
    }

    return " excl. BTW"
}