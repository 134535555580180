import React from 'react';
import { Container } from '@mui/system';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { RootState } from '../redux/store';
import { Switch } from '@mui/material';
import { useState, useEffect } from 'react';
import { setVat } from '../redux/vatSlice';
import { alpha, styled } from '@mui/material/styles';

const PinkSwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: '#E51C3D',
    '&:hover': {
      backgroundColor: alpha('#E51C3D', theme.palette.action.hoverOpacity),
    },
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: '#E51C3D',
  },
}));

const VatToggle = () => {
  const { includeVat } = useSelector((state: RootState) => state.vat);
  const dispatch = useDispatch();

  const [checked, setChecked] = useState(false);
  const [hover, setHover] = useState(false);

  // Why do we need this useEffect handler, we can simply use includeVat if we refactor includeVat to be a boolean ?
  useEffect(() => {
    if (includeVat === 'yes') {
      setChecked(true);
    } else {
      setChecked(false);
    }
  }, [includeVat]);

  const includeVatHandler = (evt: React.ChangeEvent<HTMLInputElement>) => {
    if (evt.target.checked) {
      dispatch(setVat('yes'));
    } else {
      dispatch(setVat('no'));
    }
  };

  return (
    <Container>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          marginBottom: 5,
          position: 'fixed',
          bottom: 20,
          left: 20,
          zIndex: 100,
          borderRadius: 5,
          backgroundColor: '#fff',
          padding: hover ? 4 : 1,
          transitionDuration: '0.2s',
        }}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >

        <PinkSwitch checked={checked} onChange={includeVatHandler} />
          <p style={{ marginRight: 6 }}>{checked ? 'Incl. ' : 'Excl. '} BTW</p>
      </div>
    </Container>
  );
};

export default VatToggle;
