import { Container } from '@mui/material';
import { useStaticQuery, graphql, Link } from 'gatsby';
import ReactMarkdown from 'react-markdown';

export const LuxuryCars = () => {
  const data = useStaticQuery(graphql`
    {
      strapiLuxe {
        body {
          data {
            body
          }
        }
        linkText
        title
        url
      }
    }
  `);
  return (
    <div className="luxury__wrapper">
      <div className="luxury__background">
        <Container
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: 500,
          }}
        >
          <div>
            <h2>{data.strapiLuxe.title}</h2>
            <ReactMarkdown>{data.strapiLuxe.body.data.body}</ReactMarkdown>
            <Link
              to={`/${data.strapiLuxe.url}`}
              className="button primary-button"
            >
              {data.strapiLuxe.linkText}
            </Link>
          </div>
        </Container>
      </div>
      <div className="luxury__car">
        <img
          src="https://ddvcms.s3.eu-west-1.amazonaws.com/luxe_auto_6f36f5edd3.webp?updated_at=2022-08-03T09:14:33.197Z"
          alt="lux-car"
          style={{ maxWidth: '100%' }}
        />
      </div>
    </div>
  );
};
