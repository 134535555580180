import React, { useState, useEffect } from 'react';
import { Rental, Rentvisie } from '@bakkie/ratality';
import ReservationHeader from './shared/reservation-header';
import { navigate } from 'gatsby';
import { ValidatorUtil } from './validator.util';
import { Container } from '@mui/system';
import OwnRisk from '../../images/svg/own_risk.png';
import Insurance from '../../images/svg/insurance.png';
import Distance from '../../images/svg/distance.png';
import Other from '../../images/svg/other.png';
import Vehicle from '../../images/svg/vehicle.png';
import VatToggle from '../vatToggle';
import { setVat } from '../../redux/vatSlice';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
declare global {
  interface Window {
    dataLayer: any;
  }
}

Rentvisie.setConfig({
  baseUrl: 'https://rentvisie-proxy-aws.herokuapp.com/api',
  client: 'ddv',
  useGuestToken: true,
});

function getOptionPrice(
  rental: Rental,
  optionType: string,
  optionId: number
): number {
  const options = rental.vehicleClass[optionType];
  const optionPerDay = options?.find(
    (option) =>
      option.optionId == optionId && option.price.unitDescription == 'per Day'
  );
  const optionPerTrip = options?.find(
    (option) =>
      option.optionId == optionId && option.price.unitDescription !== 'per Day'
  );
  if (optionPerDay) {
    return optionPerDay.price.value * rental.days;
  } else if (optionPerTrip) {
    return optionPerTrip.price.value;
  } else {
    return 0;
  }
}

function getPrice(rental: Rental, selectedOptions: SelectableOptions): number {
  const basePrice = rental.totalPrice.value;
  const optionsPrice = Object.keys(selectedOptions)
    .map((key) =>
      selectedOptions[key]
        .map((optionId) => getOptionPrice(rental, key, optionId))
        .reduce((acc, value) => acc + value, 0)
    )
    .reduce((acc, value) => acc + value, 0);

  return basePrice + optionsPrice;
}

interface SelectableOptions {
  insuranceOptions?: number[];
  mileagePackageOptions?: number[];
  optionalOptions?: number[];
}
const ReservationSelectUpsell = ({ rental, id }) => {
  const [selectedOptions, setSelectedOptions] = useState<SelectableOptions>({});
  const [showLoading, setShowLoading] = useState(false);
  const includeVat = useSelector((state: RootState) => state.vat.includeVat);
  const dispatch = useDispatch();
  const [checked, setChecked] = useState(false);
  useEffect(() => {
    if (includeVat === 'yes') {
      setChecked(true);
    } else {
      setChecked(false);
    }
  }, [includeVat]);
  const includeVatHandler = () => {
    if (includeVat === 'no') {
      dispatch(setVat('yes'));
    } else {
      dispatch(setVat('no'));
    }
  };

  //   let includeVat = 'no';
  //   if (typeof window !== 'undefined') {
  //     includeVat = localStorage.getItem('includeVat');
  //   }

  const valueChanges = (key: string, ids: number[]) => {
    const newSelectedOptions = { ...selectedOptions, [key]: ids };
    setSelectedOptions(newSelectedOptions);
  };

  const handleCheckBox = (key: string, event: any) => {
    const checked = event.target.checked;
    const value = event.target.value;
    const currentValues = selectedOptions[key] ?? [];
    if (checked) {
      valueChanges(key, [...currentValues, value]);
    } else {
      valueChanges(
        key,
        currentValues.filter((id) => id !== value)
      );
    }
  };
  const mapped = Object.entries(selectedOptions).map(
    ([key, value]) => `${key}=${value.join(',')}`
  );

  const createReservation = async (rentalId) => {
    setShowLoading(true);
    createReservationAndSetOptions(rentalId);
  };

  const createReservationAndSetOptions = async (rentalId) => {
    const reservation = await Rentvisie.postReservation(rentalId);
    const reservationReference = await reservation.reservationReference;
    const bookingReference = await reservation.rentals[0].bookingReference;

    const hasInsuranceOptions = ValidatorUtil.validateInsuranceOptions(
      selectedOptions.insuranceOptions
    );
    const hasMileageOptions = ValidatorUtil.validateMileageOptions(
      selectedOptions.mileagePackageOptions
    );
    const hasOptionalOptions = ValidatorUtil.validateOptionalOptions(
      selectedOptions.optionalOptions
    );

    const actions = [];
    if (hasInsuranceOptions) {
      const insuranceId = selectedOptions.insuranceOptions[0];
      await actions.push(
        Rentvisie.setInsurance(
          reservationReference,
          bookingReference,
          insuranceId
        )
      );
    }
    if (hasMileageOptions) {
      const mileageId = selectedOptions.mileagePackageOptions[0];
      await actions.push(
        Rentvisie.setMileageOption(
          reservationReference,
          bookingReference,
          mileageId
        )
      );
    }
    if (hasOptionalOptions) {
      const optionalOptions = selectedOptions.optionalOptions.map((id) =>
        Number(id)
      );
      await actions.push(
        Rentvisie.setOptionalOptions(
          reservationReference,
          bookingReference,
          optionalOptions
        )
      );
    }

    await Promise.all(actions);

    const url = `/huren/checkout?${mapped.join('&')}&reservationReference=${
      reservation.reservationReference
    }`;
    setTimeout(navigate(url), 500);
  };

  const [showButton, setShowButton] = useState(false);
  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.pageYOffset > 250) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    });
  }, []);

  const dataLayer = window.dataLayer || [];
  const [pushedDataLayer, setPushedDataLayer] = useState(false);
  if (!pushedDataLayer) {
    dataLayer.push({ ecommerce: null });
    dataLayer.push({
      event: `begin_checkout`,
      ecommerce: {
        items: [
          {
            item_name: `${rental.vehicleClass.description}`,
            item_id: `${rental.vehicleClass.vehicleClassId}`,
            price: `${rental.price.value}`,
            price_excl_vat: `${((rental.price.value / 1.21) * 100) / 100}`,
            currency: 'EUR',
            item_category: `${rental.vehicleClass.category.categoryId}`,
            quantity: 1,
            pick_up_location_id: `${rental.pickupLocation.locationId}`,
            pick_up_location: `${rental.pickupLocation.town}`,
          },
        ],
      },
    });
    setPushedDataLayer(true);
  }

  // Separate Mileagepackageoptions => Backend updates required for robust solutions //
  const totalDays = rental.days;

  const getPriceOption = (price, type) => {
    if (type === 'PerDay') {
      return price * totalDays;
    }
    return price;
  };

  const insuranceFilter = rental.vehicleClass.optionalOptions
    ? rental.vehicleClass.optionalOptions.filter(
        (option) =>
          option.category === 'Verzekering (Overig)' &&
          !option.description.includes('Risico')
      )
    : [];
  const vehicleFilter = rental.vehicleClass.optionalOptions
    ? rental.vehicleClass.optionalOptions.filter(
        (option) => option.category === 'Voertuig'
      )
    : [];
  const optionFilter = rental.vehicleClass.optionalOptions
    ? rental.vehicleClass.optionalOptions.filter(
        (option) =>
          option.category !== 'Verzekering (Overig)' &&
          option.category !== 'Voertuig' &&
          option.category !== 'Populair' &&
          option.category !== 'AdditionalDriver' &&
          option.category !== 'ChildSeat' &&
          option.category !== 'Navigation' &&
          option.category !== 'RefuelingService' &&
          option.category !== 'Schade'
      )
    : [];
  const compare = (a, b) => {
    if (a.price.value < b.price.value) {
      return -1;
    }
    if (a.price.value > b.price.value) {
      return 1;
    }
    return 0;
  };

  return (
    <Container>
      <VatToggle />
      <ReservationHeader activeStepIndex={2} isCar={true} />
      <div className="reservation__title">
        <h2>Personaliseer jouw voertuig</h2>
        
        <p>De borg bedraagt altijd € 250.</p>
      </div>

      {showLoading ? (
        <h3>Ogenblik geduld alstublieft.</h3>
      ) : (
        <div className="reservation-select-wrapper my-l">
          <div className="reservation-select-left">
            {rental.vehicleClass.insuranceOptions && (
              <div className="extra-wrapper">
                <div className="extra-wrapper__title">
                  <img src={OwnRisk} alt="Eigen Risico en Borg"></img>
                  <h3>Verzekeringsopties</h3>
                </div>
                <div
                  className="extrabox-grid"
                  onChange={(selected) =>
                    valueChanges('insuranceOptions', [selected.target.id])
                  }
                >
                  {rental.vehicleClass.insuranceOptions
                    .sort(compare)
                    .map((option) => (
                      <div key={option.optionId}>
                        <input
                          type="radio"
                          id={option.optionId}
                          name="insuranceId"
                          value={option.price.value}
                          defaultChecked={option.price.value === 0}
                        />
                        <label htmlFor={option.optionId}>
                          <div className="extrabox">
                            <h3>
                              {option.description} €{' '}
                              {includeVat === 'yes'
                                ? (
                                    (getPriceOption(
                                      option.price.value,
                                      option.price.unitType
                                    ) *
                                      100) /
                                    100
                                  )
                                    .toFixed(2)
                                    .toString()
                                    .replace('.', ',')
                                : (
                                    getPriceOption(
                                      option.price.value,
                                      option.price.unitType
                                    ) / 1.21
                                  )
                                    .toFixed(2)
                                    .toString()
                                    .replace('.', ',')}
                            </h3>
                            <p className="dark-gray">
                              Verminder je eigen risico tot €{' '}
                              {option.insuranceExcessAmount.value}
                            </p>
                          </div>
                        </label>
                      </div>
                    ))}
                </div>
              </div>
            )}

            {insuranceFilter.length > 0 && (
              <div className="extra-wrapper">
                <div className="extra-wrapper__title">
                  <img src={Insurance} alt="Overige Verzekeringen"></img>
                  <h3>Verzekeringen</h3>
                </div>
                <div className="extrabox-grid">
                  {insuranceFilter.sort(compare).map((option) => (
                    <div key={option.optionId}>
                      <input
                        type="checkbox"
                        id={option.optionId}
                        name="optionId"
                        value={option.optionId}
                        onChange={(event) =>
                          handleCheckBox('optionalOptions', event)
                        }
                      />
                      <label htmlFor={option.optionId}>
                        <div className="extrabox">
                          <h3>
                            {option.description} (€{' '}
                            {includeVat === 'yes'
                              ? (
                                  (getPriceOption(
                                    option.price.value,
                                    option.price.unitType
                                  ) *
                                    100) /
                                  100
                                )
                                  .toFixed(2)
                                  .toString()
                                  .replace('.', ',')
                              : (
                                  getPriceOption(
                                    option.price.value,
                                    option.price.unitType
                                  ) / 1.21
                                )
                                  .toFixed(2)
                                  .toString()
                                  .replace('.', ',')}
                            )
                          </h3>
                          <span className="dark-gray">
                            {option.labelMarkup.replace(/(<([^>]+)>)/gi, '')}
                          </span>
                        </div>
                      </label>
                    </div>
                  ))}
                </div>
              </div>
            )}
            {vehicleFilter.length > 0 && (
              <div className="extra-wrapper">
                <div className="extra-wrapper__title">
                  <img src={Vehicle} alt="Voertuig Opties"></img>
                  <h3>Voertuig Opties</h3>
                </div>
                <div className="extrabox-grid">
                  {vehicleFilter.sort(compare).map((option) => (
                    <div key={option.optionId}>
                      <input
                        type="checkbox"
                        id={option.optionId}
                        name="optionId"
                        value={option.optionId}
                        onChange={(event) =>
                          handleCheckBox('optionalOptions', event)
                        }
                      />
                      <label htmlFor={option.optionId}>
                        <div className="extrabox">
                          <h3>
                            {option.description} (€{' '}
                            {includeVat === 'yes'
                              ? (
                                  (getPriceOption(
                                    option.price.value,
                                    option.price.unitType
                                  ) *
                                    100) /
                                  100
                                )
                                  .toFixed(2)
                                  .toString()
                                  .replace('.', ',')
                              : (
                                  getPriceOption(
                                    option.price.value,
                                    option.price.unitType
                                  ) / 1.21
                                )
                                  .toFixed(2)
                                  .toString()
                                  .replace('.', ',')}
                            )
                          </h3>
                          <span className="dark-gray">
                            {option.labelMarkup.replace(/(<([^>]+)>)/gi, '')}
                          </span>
                        </div>
                      </label>
                    </div>
                  ))}
                </div>
              </div>
            )}
            <div className="extra-wrapper">
              <div className="extra-wrapper__title">
                <img src={Other} alt="Overige Opties"></img>
                <h3>Overige Opties</h3>
              </div>
              <div className="extrabox-grid">
                {optionFilter.sort(compare).map((option) => (
                  <div key={option.optionId}>
                    <input
                      type="checkbox"
                      id={option.optionId}
                      name="optionId"
                      value={option.optionId}
                      onChange={(event) =>
                        handleCheckBox('optionalOptions', event)
                      }
                    />
                    <label htmlFor={option.optionId}>
                      <div className="extrabox">
                        <h3>
                          {option.description} (€{' '}
                          {includeVat === 'yes'
                            ? (
                                (getPriceOption(
                                  option.price.value,
                                  option.price.unitType
                                ) *
                                  100) /
                                100
                              )
                                .toFixed(2)
                                .toString()
                                .replace('.', ',')
                            : (
                                getPriceOption(
                                  option.price.value,
                                  option.price.unitType
                                ) / 1.21
                              )
                                .toFixed(2)
                                .toString()
                                .replace('.', ',')}
                          )
                        </h3>
                        <span className="dark-gray">
                          {option.labelMarkup.replace(/(<([^>]+)>)/gi, '')}
                        </span>
                      </div>
                    </label>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="reservation-select-right">
            <div className="reservation-contact">
              <div className="reservation-contact__photo">
                <img
                  src={rental.vehicleClass.images[0].imageUrl}
                  alt={rental.vehicleClass.images[0].fileName}
                ></img>
              </div>
              <div className="reservation-contact__text">
                <p>
                  <span className="bold">{rental.pickupLocation.town}</span>{' '}
                  <br></br>
                  Ophalen: {rental.pickupDate} {rental.pickupTime} <br></br>
                  Retour: {rental.dropOffDate} {rental.dropOffTime}
                </p>

                <p className="small-top">Nu voor slechts:</p>
                <h3>
                  <span className="extrabox__price">
                    {/* € {rental.price.value.toFixed(2)},- */}
                    {includeVat === 'yes'
                      ? ` € ${' '} ${(
                          (getPrice(rental, selectedOptions) * 100) /
                          100
                        )
                          .toFixed(2)
                          .toString()
                          .replace('.', ',')}`
                      : ` € ${' '} ${(getPrice(rental, selectedOptions) / 1.21)
                          .toFixed(2)
                          .toString()
                          .replace('.', ',')}`}
                    {includeVat === 'yes' ? ' incl. BTW' : ' excl. BTW'}
                  </span>
                </h3>
                <p>
                  <span className="small-top">Totaal vrije kilometers:</span>

                  <span style={{ fontWeight: 600 }}>
                    {rental.includedMileage && rental.includedMileage > 0
                      ? ` ${rental.includedMileage}`
                      : ' 0'}
                  </span>
                </p>
                {rental.vehicleClass.marketingOverMileagePrice && (
                  <p>
                    <span className="small-top">Meerkilometers:</span>

                    {includeVat === 'yes'
                      ? ` € ${' '} ${rental.vehicleClass.marketingOverMileagePrice.value
                          .toFixed(2)
                          .toString()
                          .replace('.', ',')}`
                      : ` € ${' '} ${(
                          rental.vehicleClass.marketingOverMileagePrice.value /
                          1.21
                        )
                          .toFixed(2)
                          .toString()
                          .replace('.', ',')}`}
                  </p>
                )}
                <p>
                <span className="small-top">Borg:</span> € 250
                </p>
                <button
                  className="primary-button"
                  onClick={() => createReservation(id)}
                >
                  Volgende Stap
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {showButton && (
        <button
          className="primary-button reservation-cta"
          onClick={() => createReservation(id)}
        >
          Volgende Stap
        </button>
      )}
    </Container>
  );
};

export default ReservationSelectUpsell;
