import { Container } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import CtaForm from '../shared/cta-form/form';

const style = {
  // position: 'absolute',
  // top: '50%',
  // transform: 'translateY(-50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

const QuoteForm = ({ open, setOpen, vehicleClassId, productid, nonReal }) => {
  const handleClose = () => setOpen(false);

  return (
    <Container>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {nonReal ? (
            <CtaForm
              includeCategories={false}
              productid={productid}
              realTime={false}
            />
          ) : (
            <CtaForm
              includeCategories={false}
              vehicleClassId={vehicleClassId}
              realTime={true}
            />
          )}
        </Box>
      </Modal>
    </Container>
  );
};

export default QuoteForm;
