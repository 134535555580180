import { Rentvisie } from '@bakkie/ratality';
import { useState, useEffect } from 'react';
import Skeletons from './loading/skeletons';
import VehicleForm from './modals/vehicleForm';
import { Link } from 'gatsby';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/store';
import {
  manipulatePriceForVat,
  manipulatePriceTagForVat,
} from '../utils/vatHelper';
import axios from 'axios';
import SearchImageCarousel from './reservation/product-carousel-search';

const Vehicles = ({ id, vehicle }) => {
  const { includeVat } = useSelector((state: RootState) => state.vat);
  const [loading, setLoading] = useState(true);
  const [isRealTime, setIsRealTime] = useState(null);
  const [vehicleClasses, setVehicleClasses] = useState([]);
  const [error, setError] = useState(undefined);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    // TODO: Move config to layout
    if (id && id.name === 'categoryId') {
      Rentvisie.setConfig({
        baseUrl: 'https://rentvisie-proxy-aws.herokuapp.com/api',
        client: 'ddv',
        useGuestToken: true,
      });

      Rentvisie.getVehicleClasses(id) // 1567 branch Purmerend
        .then(setVehicleClasses)
        .then(() => setIsRealTime(true))
        .catch(setError)
        .finally(() => setLoading(false));
    } else if (id && id.name === 'groupid') {
      axios
        .get(
          `https://ddv-web-cms.herokuapp.com/api/grouped-products?group=${id.id}`
        )
        .then((res) => {
          setVehicleClasses(res.data);
          setIsRealTime(false);
          setLoading(false);
        })
        .catch((err) => {
          setError(err);
          setLoading(false);
        });
    } else if (vehicle) {
      setVehicleClasses(vehicle);
      setIsRealTime(false);
      setLoading(false);
    }
  }, [id, vehicle]);

  if (loading || isRealTime === null) {
    return <Skeletons />;
  }
  if (error) {
    return <p>{error.message}</p>;
  }

  const compare = (a, b) => {
    if (a.marketingPrices && b.marketingPrices) {
      if (a.marketingPrices[0].value < b.marketingPrices[0].value) {
        return -1;
      }
      if (a.marketingPrices[0].value > b.marketingPrices[0].value) {
        return 1;
      }
      return 0;
    }
  };

  return (
    <div>
      <div className="cards__grid">
        {vehicleClasses.length === 0 && <p>Tijdelijk niet beschikbaar</p>}
        {vehicleClasses.sort(compare).map((item) => (
          <Link
            to={
              isRealTime
                ? `/${item.subLabelMarkup
                    ?.replace(/(<([^>]+)>)/gi, '')
                    .toLowerCase()
                    .replaceAll(' ', '-')}`
                : `/${item.group?.slug.toLowerCase()}/${item.slug.toLowerCase()}`
            }
            className="card"
          >
            <div className="card__content">
              <div className="card__title-wrapper">
                <div className="card__title">
                  <h3>{isRealTime ? item.description : item.title}</h3>
                </div>
                <div className="card__info-icon">i</div>
              </div>
                {isRealTime ? (
                  <SearchImageCarousel assets={item.images} isStrapi={false} />
                ) : (
                  <SearchImageCarousel assets={item.mediaslider} isStrapi={true} />
                )}
              <div className="card__features">
                {item.tags?.map((tag) => (
                  <div className="card__feature" key={tag.tagId}>
                    {isRealTime ? tag.tagDescription : tag.naam}
                  </div>
                ))}
              </div>
            </div>
            {isRealTime ? (
              <div className="card__price">
                Vanaf €{' '}
                {item.marketingPrices
                  ? manipulatePriceForVat(
                      item.marketingPrices[0].value,
                      includeVat
                    )
                  : '0'}
                {manipulatePriceTagForVat(includeVat)}
              </div>
            ) : (
              <div className="card__price">
                Vanaf €{' '}
                {item.prices
                  ? manipulatePriceForVat(item.prices[0].prijs, includeVat)
                  : '0'}
                {manipulatePriceTagForVat(includeVat)}
              </div>
            )}

            <div className="card__more-info">Meer info</div>
          </Link>
        ))}
      </div>
      <VehicleForm open={open} setOpen={setOpen} />
    </div>
  );
};

export default Vehicles;
