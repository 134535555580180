import { useState, useEffect } from 'react';
import Categories from './categories';
import DateTimeRangePicker from './datetimerangepicker';
import LocationSelect from './location-select';
import Settings from '../../../components/settings.json';
import { useSelector } from 'react-redux';

const CtaForm = (props) => {
  const [showDateInPastWarning, setShowDateInPastWarning] = useState(false);
  const [showThirtyDaysWarning, setShowThirtyDaysWarning] = useState(false);
  const [showCloseByPickupWarning, setShowCloseByPickupWarning] =
    useState(false);
  const [showSundayWarning, setShowSundayWarning] = useState(false);
  const [showNextSundayWarning, setShowNextSundayWarning] = useState(false);
  const [drieDagWarning, setDrieDagWarning] = useState(false);
  const { startDatez, endDatez, location } = useSelector(
    (state) => state.datelocation
  );
  const [realTime, setRealTime] = useState(true);
  useEffect(() => {
    if (props.realTime !== undefined) {
      setRealTime(props.realTime);
    }
  }, [props.realTime]);
  const getDifferenceInDays = (date1, date2) => {
    const diffInMs = Math.abs(date2 - date1);
    return Math.ceil(diffInMs / (1000 * 60 * 60 * 24));
  };

  useEffect(() => {
    if (
      getDifferenceInDays(endDatez, startDatez) < 3 &&
      location?.value === 6017
    ) {
      setDrieDagWarning(true);
    } else {
      setDrieDagWarning(false);
    }
  }, [startDatez, endDatez, location]);

  const today = new Date();
  let tomorrow = new Date();
  tomorrow.setDate(today.getDate() + 1);
  tomorrow.setHours(Settings.dayStartHour);
  tomorrow.setMinutes(0);
  let endOfTomorrow = new Date();
  endOfTomorrow.setDate(today.getDate() + 1);
  endOfTomorrow.setHours(Settings.dayEndHour);
  endOfTomorrow.setMinutes(0);

  let startRent = {};
  let endRent = {};
  let diffMinutes = 0;

  if (props.pickupDate && props.pickupTime) {
    const ddmmyyyy = props.pickupDate.split('-');
    const hhmm = props.pickupTime.split(':');
    startRent = new Date(ddmmyyyy[1] + '/' + ddmmyyyy[0] + '/' + ddmmyyyy[2]);
    startRent.setHours(hhmm[0]);
    startRent.setMinutes(hhmm[1]);

    if (props.returnDate && props.returnTime) {
      const ddmmyyyy = props.returnDate.split('-');
      const hhmm = props.returnTime.split(':');
      endRent = new Date(ddmmyyyy[1] + '/' + ddmmyyyy[0] + '/' + ddmmyyyy[2]);
      endRent.setHours(hhmm[0]);
      endRent.setMinutes(hhmm[1]);
    } else {
      endRent = endOfTomorrow;
    }
  } else {
    if (
      new Date().getHours() >=
      Settings.dayEndHour - Math.round(Settings.leadTime / 60)
    ) {
      startRent = new Date();
      startRent.setDate(today.getDate() + 1);
      startRent.setHours(Settings.dayStartHour);
      startRent.setMinutes(0);
      endRent = endOfTomorrow;
    } else {
      startRent = new Date();
      startRent.setDate(today.getDate());
      startRent.setHours(
        today.getHours() +
          Math.ceil((startRent.getMinutes() + Settings.leadTime) / 60)
      );
      startRent.setMinutes(0);
      endRent = new Date(startRent);
      endRent.setHours(endRent.getHours() + Settings.minTimeRange);
      if (endRent.getHours() < Settings.dayStartHour) {
        endRent.setHours(Settings.dayStartHour);
      }
      diffMinutes = Math.round(
        Math.abs(startRent - endRent) / 1000 / 60 - 0.99
      );
      if (
        diffMinutes / 60 <
          Settings.dayStartHour +
            24 -
            Settings.dayEndHour +
            Settings.minTimeRange &&
        endRent.getHours() < Settings.dayStartHour + Settings.minTimeRange
      ) {
        endRent.setHours(
          Settings.dayStartHour +
            24 -
            Settings.dayEndHour +
            Settings.minTimeRange -
            24 +
            startRent.getHours()
        );
      }
    }
  }

  const [startDate, setStartDate] = useState(startRent);
  const [endDate, setEndDate] = useState(endRent);
  const parsedStartDate = startDate.toLocaleString('nl-NL');
  const parsedEndDate = endDate.toLocaleString('nl-NL');

  const vehicleClassId = props.vehicleClassId || props.productid || '';

  useEffect(() => {
    if (startDate.getDay() === 0 && new Date().getDay() === 0) {
      setShowSundayWarning(true);
    } else {
      setShowSundayWarning(false);
    }
  }, [startDate]);

  return (
    <form
      action={realTime ? `/huren/voertuig-kiezen` : `/huren/offerte-stap2`}
      method="get"
    >
      {showDateInPastWarning && (
        <div className="cta-form__warning-wrapper">
          <div className="cta-form__warning"> ! </div>
          <div>
            <p className="cta-form__warning2">
              {' '}
              Er is een tijd in het verleden geselecteerd of de retourtijd werd
              gelijk of kwam voor de ophaaltijd: De tijd is aangepast naar een
              andere gelegenheid.
            </p>
          </div>
        </div>
      )}
      {showThirtyDaysWarning && (
        <div className="cta-form__warning-wrapper">
          <div className="cta-form__warning"> ! </div>
          <div>
            <p className="cta-form__warning2">
              Voor huurperiodes vanaf 30 dagen maken wij graag een offerte op
              maat. Neem contact met ons op via{' '}
              <a href="tel:+3251792735">+32 51 79 27 35</a>
            </p>
            <p className="cta-form__warning2">
              Kortere huurperiodes kunnen gespecificeerd worden door de datums
              aan te passen.
            </p>
          </div>
        </div>
      )}
      {showCloseByPickupWarning && (
        <div className="cta-form__warning-wrapper">
          <div className="cta-form__warning"> ! </div>
          <div>
            <p className="cta-form__warning2">
              Wij kunnen voor deze periode online geen beschikbaarheid
              garanderen.
            </p>
            <p className="cta-form__warning2">
              Neem contact met ons op via{' '}
              <a href="tel:+3251792735">+32 51 79 27 35</a>
            </p>
          </div>
        </div>
      )}
      {showSundayWarning && (
        <div className="cta-form__warning-wrapper">
          <div className="cta-form__warning"> ! </div>
          <div>
            <p className="cta-form__warning2">
              Op zondag zijn wij gesloten, selecteer een andere dag.
            </p>
          </div>
        </div>
      )}

      {showNextSundayWarning && (
        <div className="cta-form__warning-wrapper">
          <div className="cta-form__warning"> ! </div>
          <div>
            <p className="cta-form__warning2">
              Op zondag zijn wij gesloten. Reserveren in telefonisch overleg via{' '}
              <a href="tel:+3251792735">+32 51 79 27 35</a>
            </p>
          </div>
        </div>
      )}
      {drieDagWarning && (
        <div className="cta-form__warning-wrapper">
          <div className="cta-form__warning"> ! </div>
          <div>
            <p className="cta-form__warning2">
              Om te huren in Meulebeke, moet u minimaal 3 dagen
              huren. Reserveren in telefonisch overleg via{' '}
              <a href="tel:+3251792735">+32 51 79 27 35</a>
            </p>
          </div>
        </div>
      )}
      <input type="hidden" name="pickupDate" value={parsedStartDate}></input>
      <input type="hidden" name="returnDate" value={parsedEndDate}></input>
      <input
        type="hidden"
        name={realTime ? 'vehicleClassId' : 'id'}
        value={vehicleClassId}
      ></input>

      <div className="cta-form">
        {props.includeCategories !== false && (
          <Categories category={props.category}></Categories>
        )}

        <div className="cta-form__search-wrapper">
          <div className="cta-form__search-input" name="location">
            <div className="cta-form__search-input-text">Locatie</div>
            <LocationSelect location={props.location}></LocationSelect>
          </div>
          <div className="cta-form__search-input" name="ophalenretour">
            <div className="cta-form__search-input-text">
              <div className="cta-form__search-wrapper-header-datetimepicker">
                <div className="cta-form__datetimerange-text">Ophalen</div>
                <div className="cta-form__datetimerange-text">Retour</div>
              </div>
            </div>

            <DateTimeRangePicker
              today={today}
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
              location={location}
              setShowDateInPastWarning={setShowDateInPastWarning}
              setShowThirtyDaysWarning={setShowThirtyDaysWarning}
              setShowCloseByPickupWarning={setShowCloseByPickupWarning}
              setShowNextSundayWarning={setShowNextSundayWarning}
              setShowSundayWarning={setShowSundayWarning}
              setDrieDagWarning={setDrieDagWarning}
            ></DateTimeRangePicker>
          </div>
          <div className="cta-form__search-input" name="aanbod">
            <input
              type="submit"
              value="Aanbod"
              className="cta-form__button button-primary"
              disabled={
                showThirtyDaysWarning ||
                showNextSundayWarning ||
                showSundayWarning ||
                drieDagWarning
              }
            />
          </div>
        </div>
      </div>
    </form>
  );
};

export default CtaForm;
