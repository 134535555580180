import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader

export function ProductImageCarousel({ assets }) {
  const customRenderThumb = (children) =>
    children.map((item) => {
      if (item.type === 'video')
        return <img src="/static/logo.5e87bf0d.svg" height="50" alt="logo" />;
      return <img src={item.props.src} height="50" alt="logo" />;
    });

  return (
    <Carousel
      showStatus={false}
      className="carousel"
      autoPlay={true}
      renderThumbs={customRenderThumb}
    >
      {assets &&
        assets.slice(0,5).map((asset) =>
          asset.ext === '.mp4' ? (
            <video width="100%" height="100%" thumb={asset.url} controls>
              <source src={asset.url} type="video/mp4"></source>
            </video>
          ) : (
            <img
              src={asset.url}
              alt={asset.description}
              title={asset.description}
              className='carousel-image'
              // height="250px"
            />
          )
        )}
    </Carousel>
  );
}

export default ProductImageCarousel;
