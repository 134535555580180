import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import { useStaticQuery, graphql } from 'gatsby';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { Link } from 'gatsby';
import Logo from '../../images/svg/logo.webp';
import Hamburger from '../../images/icons/hamburger.png';
import Close from '../../images/icons/close-icon-dark.png';
import { Container } from '@mui/material';
import CtaForm from '../shared/cta-form/form';
import { useLocation } from '@reach/router';
const drawerWidth = 300;

const navTopItems = [
  { name: 'Getuigenissen', url: 'getuigenissen' },
  { name: 'Veelgestelde Vragen', url: 'veelgestelde-vragen' },
];

const Navbar = ({ standard }) => {
  const location = useLocation();

  const [mobileOpen, setMobileOpen] = React.useState(false);
  const data = useStaticQuery(graphql`
    {
      strapiNavbar {
        navItems {
          linkText
          url
          strapi_id
          dropdown {
            linkText
            strapi_id
            url
          }
        }
      }
    }
  `);
  const navItems = data.strapiNavbar.navItems;
  const machineItems = data.strapiNavbar.navItems.find((item) => item.url === 'machines').dropdown;


  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const [ReserveerNu, setReserveerNu] = React.useState(false);

  const toggleReserveerNu = () => {
    setReserveerNu(!ReserveerNu);
  };
  const anchor = 'top';

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <img src={Close} alt="close menu" width={24} style={{ padding: 24 }} />
      <Divider />
      <List>
        {navItems.map((item) => (
          <Link
            to={`/${item.url}`}
            key={item.strapi_id}
            activeClassName="active-nav"
          >
            <ListItemButton sx={{ textAlign: 'center' }}>
              <ListItemText primary={item.linkText} />
            </ListItemButton>
          </Link>
        ))}
        {navTopItems.map((item) => (
          <Link
            to={`/${item.url}`}
            key={item.name}
            activeClassName="active-nav"
          >
            <ListItemButton sx={{ textAlign: 'center' }}>
              <ListItemText primary={item.name} />
            </ListItemButton>
          </Link>
        ))}
      </List>
    </Box>
  );


  const container = typeof window !== undefined ? () => window.document.body : undefined;

  return (
    <div className="navbar-wrapper">
      <Container>
        <div className="navbar-top">
          <div style={{ margin: 0 }}>
            <Link to="/" activeClassName="active-nav active-top">
              Auto Verhuur
            </Link>

            <Link to="/machine-verhuur" activeClassName="active-nav active-top">
              Machine Verhuur
            </Link>
          </div>
          <div>
            {navTopItems.map((item) => (
              <Link to={`/${item.url}`} activeClassName="active-nav active-top">
                {item.name}
              </Link>
            ))}
          </div>
        </div>
      </Container>

      <Box sx={{ display: 'flex' }}>
        <AppBar component="nav" className={standard ? '' : 'sticky'}>
          <Container>
            <Toolbar>
              <Typography
                component="div"
                sx={{ flexGrow: 1, padding: '10px 0' }}
              >
                <Link
                  to={
                    location.pathname === '/machine-verhuur' ||
                    location === '/machines' ||
                    machineItems.find((item) => `/${item.url}` === location.pathname)
                      ? '/machine-verhuur'
                      : '/'
                  }
                >
                  <img
                    src={Logo}
                    alt="RentVisie - Next Generation"
                    width={130}
                  ></img>
                </Link>
              </Typography>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ mr: 2, display: { md: 'none' } }}
              >
                <img src={Hamburger} alt="menu" />
              </IconButton>
              <Box
                sx={{
                  display: { xs: 'none', md: 'block' },
                  alignItems: 'center',
                }}
              >
                {navItems.map((item) => (
                  <Link
                    to={`/${item.url}`}
                    className={`button nav-button ${
                      item.dropdown?.length > 0 && 'dropdown'
                    }`}
                    key={item.strapi_id}
                    activeClassName="active-nav"
                  >
                    {item.linkText}
                    {item.dropdown?.length > 0 && (
                      <div class="dropdown-content">
                        {item.dropdown.map((dropdownItem) => (
                          <Link
                            to={`/${dropdownItem.url}`}
                            key={dropdownItem.strapi_id}
                            activeClassName="active-nav"
                          >
                            {dropdownItem.linkText}
                          </Link>
                        ))}
                      </div>
                    )}
                  </Link>
                ))}
                <Drawer
                  anchor={anchor}
                  open={ReserveerNu}
                  onClose={toggleReserveerNu}
                >
                  {
                    <div className="hero__background-navbar">
                      <div
                        className="hero__close-button"
                        onClick={toggleReserveerNu}
                      >
                        X
                      </div>
                      <Container>
                        <div className="hero__cta-navbar">
                          <CtaForm></CtaForm>
                        </div>
                      </Container>
                    </div>
                  }
                </Drawer>
              </Box>
            </Toolbar>
          </Container>
        </AppBar>
        <Box component="nav">
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: 'block', sm: 'none' },
              '& .MuiDrawer-paper': {
                boxSizing: 'border-box',
                width: drawerWidth,
              },
            }}
          >
            {drawer}
          </Drawer>
        </Box>
      </Box>
    </div>
  );
};

export default Navbar;
