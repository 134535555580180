import Layout from "../components/layout"
import EmailIcon from '../images/icons/mail.png'
import AddressIcon from '../images/icons/location.png'
import PhoneIcon from '../images/icons/phone.png'
import { Container } from "@mui/system"
import { Map } from "../components/Map"
import Form from "../components/ContactForm"
import { useStaticQuery, graphql } from "gatsby"
import SEO from "../components/seo"


const Locaties = () => {
    const data = useStaticQuery(graphql`
        {
          strapiLocatie {
            seoBasic {
                seoMetadescription
                seoTitle
              }
            locations {
              image {
                url
                caption
                alternativeText
              }
              adres
              email
              strapi_id
              title
              telefoon
              locatie
            }
          }
        }
      `)

    const locations = data.strapiLocatie.locations
    return (
        <Layout>
            <SEO title='Locaties DDV Autoverhuur | Meulebeke en Oostende' description="Huur uw voertuigen bij DDV Autoverhuur in Oostende of Meulebeke. Al vanaf 34 euro." index={true} follow={true}></SEO>
            <Container>
                <section>
                    <h1>Locaties</h1>
                    <div className="grid-66">
                        <div className="cards flex-column-mobile">
                            {locations.map((location) => (
                                <div className="card" key={location.strapi_id}>
                                    <div className='card__title-wrapper'>
                                        <div className='card__title'>
                                            <h3>{location.locatie}</h3>
                                        </div>
                                    </div>
                                    <div className='card__image'>
                                        <img style={{ maxWidth: '100%' }} src={location.image.url} alt={location.image.alternativeText} title={location.image.caption}></img>
                                    </div>
                                    <div className="card__content">
                                        <ul>
                                            <li><img src={AddressIcon} alt='address' /> {location.adres}</li>
                                            <li><img src={EmailIcon} alt='email' /> <a href={`mailto:${location.email}`}>{location.email}</a></li>
                                            <li><img src={PhoneIcon} alt='phone' /> <a href={`tel:${location.phone}`}>{location.telefoon}</a></li>
                                        </ul>
                                        <span className="description-label">Locatie</span>
                                        <p className="description">{location.locatie}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="cards">
                            <div className="card" style={{ padding: '0 1rem 0 1rem' }}>
                                <h3>Contacteer ons</h3>
                                Voor huurgerelateerde vragen verzoeken wij u om direct contact op te nemen met de locatie.
                                Voor andere vragen kunt u direct contact opnemen met het hoofdkantoor via onderstaand formulier.
                                <Form />
                            </div>
                        </div>
                    </div>
                </section>
                <Map />
            </Container>
        </Layout>
    )
}

export default Locaties