import Layout from "../components/layout";
import { Link } from "gatsby";

const Admin = () => (
    <Layout>
        <div style={{height:'90vh', display: 'flex', flexDirection:'column', alignItems:'center', justifyContent:'center'}}>
            <a href='https://ddv-web-cms.herokuapp.com/admin' className="secondary-button button" style={{padding:'24px 48px'}} target="_blank" rel='noreferrer'>Naar CMS</a>
        </div>
    </Layout>
);

export default Admin;