import { useState } from "react";
import { useForm } from "react-hook-form";
import axios from 'axios';
import Settings from "./settings.json"

export default function Form() {
  const [formSubmitted, setFormSubmitted] = useState(false)
  const { register, formState: { errors }, handleSubmit } = useForm();
  const onSubmit = (data) => {
    axios.post(Settings.contactUrl, { data })
      .then(setFormSubmitted(true))
  };

  return (
    !formSubmitted
      ? (
        <form onSubmit={handleSubmit(onSubmit)} className='form-wrapper'>
          <label>Naam</label>
          <input
            type="text"
            {...register("naam", { required: true, maxLength: 180 })}
          />
          {errors.naam && <p>Controleer dit veld</p>}
          <label>E-mail</label>
          <input
            type="text"
            {...register("email", {
              required: true,
              pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            })}
          />
          {errors.email && <p>Controleer dit veld</p>}
          <label>Telefoon</label>
          <input
            type="tel"
            {...register("telefoon", {
              required: true,
              maxLength: 11,
              minLength: 8
            })}
          />
          {errors.telefoon && <p>Controleer dit veld</p>}

          <label>Bericht</label>
          <textarea
            rows="4"
            {...register("bericht", { required: true })}
          />
          {errors.bericht && <p>Controleer dit veld</p>}

          <input type="submit" />
        </form>
      )
      : <h3>Wij hebben uw contactverzoek in goede orde ontvangen.</h3>
  );
}