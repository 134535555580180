import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader

export function SearchImageCarousel({ assets, isStrapi }) {
  console.log('assets', assets)
  return (
    <Carousel
      showStatus={false}
      className="carousel"
      autoPlay={false}    >
      {assets &&
        assets.slice(0,3).map((asset) =>
            <img
              src={isStrapi ? asset.url : asset.imageUrl}
              alt={isStrapi ? asset.alternativeText : asset.description}
              title={isStrapi ? asset.name : asset.description}
              className='carousel-image'
              key={asset.id}
            />
        )}
    </Carousel>
  );
}

export default SearchImageCarousel;
