import isEmail from 'validator/lib/isEmail'

export class ValidatorUtil {

    private static nameValidator = /^[a-z ,.'-]{2,50}$/i;
    private static vatValidator = /^[a-zA-Z , 0-9]{12,18}$/;
    private static phoneValidator = /^[\+]?[0-9]{4}?[-]?[0-9]{6,9}$/;
    private static zipCodeValidator = /^(\d{4})$/;
    private static houseNumberValidator = /^(\d{1,4})$/;
    private static additionValidator = /^[a-zA-Z ,. -'\d]{1,3}/iu;
    private static streetValidator = /^[a-z ,.'-]{2,140}$/iu;

    static validateName(name: string): boolean {
        return ValidatorUtil.nameValidator.test(name);
    }
    static validateVatNumber(vatNumber: string): boolean {
        return ValidatorUtil.vatValidator.test(vatNumber);
    }
    static validatePhone(phoneNumber: string): boolean {
        return ValidatorUtil.phoneValidator.test(phoneNumber)
    }
    static validateZipCode(zipCode: string): boolean {
        return ValidatorUtil.zipCodeValidator.test(zipCode)
    }
    static validateEmail(email: string): boolean {
        return isEmail(email)
    }
    static validateHouseNumber(houseNumber: string): boolean {
        return ValidatorUtil.houseNumberValidator.test(houseNumber)
    }
    static validateAddition(addition: string): boolean {
        return ValidatorUtil.additionValidator.test(addition);
    }
    static validateStreet(street: string): boolean {
        return ValidatorUtil.streetValidator.test(street);
    }
    static validateInsuranceOptions = (insuranceOptions: any) => {
        if (insuranceOptions) {return true}
    }
    static validateMileageOptions = (mileageOptions: any) => {
        if (mileageOptions) {return true}
    }
    static validateOptionalOptions = (optionalOptions: any) => {
        if (optionalOptions) {return true}
    }
    static validateRentalInsuranceOptions = (insuranceOptions: any) => {
        if (insuranceOptions) {return true}
    }
    static validateRentalMileageOptions = (mileageOptions: any) => {
        if (mileageOptions) {return true}
    }
    static validateRentalOptionalOptions = (optionalOptions: any) => {
        if (optionalOptions) {return true}
    }
    static validateKassakoopje = (kassaKoopje) => {
        if (kassaKoopje.length > 0) { return true }
    }
    static validateCustomer = (hasValidFirstName, hasValidLastName, hasValidPhone, hasValidEmail, hasValidZipCode, hasValidHouseNumber, hasValidStreet) => {
        if (
            hasValidFirstName &&
            hasValidLastName &&
            hasValidPhone &&
            hasValidEmail &&
            hasValidZipCode &&
            hasValidHouseNumber &&
            hasValidStreet
        ) { return true }
    }
}