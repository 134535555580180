import Settings from '../../settings.json';
import Dumper from '../../../images/svg/dumper.png';
import Excavator from '../../../images/svg/excavator.png';
import Personenwagen from '../../../images/svg/personenwagen.svg';
import Luxewagen from '../../../images/svg/luxewagen.svg';
import Trailer from '../../../images/svg/aanhangwagen.svg';
import Machine from '../../../images/svg/icons8-crane-truck.png';
import Loader from '../../../images/svg/loader.png';
import Koelwagen from '../../../images/svg/flaticon-freeze-truck.png';

import { navigate } from 'gatsby';

const Categories = ({ category }) => {
  if (!category) {
    category = Settings.groupId.minigraaf_kranen_tot_8;
  } else {
    switch (category) {
      case 'minigraaf_kranen_tot_1':
        category = Settings.groupId.minigraaf_kranen_tot_1;
        break;
      case 'dumpers':
        category = Settings.groupId.dumpers;
        break;
      case 'knikladers':
        category = Settings.groupId.knikladers;
        break;
      case 'kraan':
        category = Settings.groupId.kraan;
        break;
      case 'trailers':
        category = Settings.groupId.trailers;
        break;
      case 'voertuigen':
        category = Settings.groupId.voertuigen;
        break;
    }
  }

  const handleDefaultChecked = (groupId) => groupId === Number(category);

  return (
    <div className="cta-categories" id="cta-categories">
      <input
        type="radio"
        id={Settings.groupId.minigraaf_kranen_tot_1}
        name="groupId"
        value={Settings.groupId.minigraaf_kranen_tot_1}
        defaultChecked={handleDefaultChecked(
          Settings.groupId.minigraaf_kranen_tot_1
        )}
      ></input>

      <label
        className="cta-category"
        htmlFor={Settings.groupId.minigraaf_kranen_tot_1}
      >
        <div>
          <img
            className="ddv-icon"
            src={Excavator}
            alt="Mini graafkraan"
            style={{ scale: '0.8' }}
          ></img>
          Mini graafkranen
        </div>
      </label>
      <input
        type="radio"
        id={Settings.groupId.dumpers}
        name="groupId"
        value={Settings.groupId.dumpers}
        defaultChecked={handleDefaultChecked(Settings.groupId.dumpers)}
      ></input>
      <label className="cta-category" htmlFor={Settings.groupId.dumpers}>
        <div>
          <img className="ddv-icon" src={Dumper} alt="Dumpers"></img>
          Dumpers
        </div>
      </label>

      <input
        type="radio"
        id={Settings.groupId.knikladers}
        name="groupId"
        value={Settings.groupId.knikladers}
        defaultChecked={handleDefaultChecked(Settings.groupId.knikladers)}
      ></input>
      <label className="cta-category" htmlFor={Settings.groupId.knikladers}>
        <div>
          <img
            className="ddv-icon"
            src={Loader}
            alt="knikladers"
            style={{ scale: '0.8' }}
          ></img>
          Knikladers
        </div>
      </label>

      <input
        type="radio"
        id={Settings.groupId.kraan}
        name="groupId"
        value={Settings.groupId.kraan}
        defaultChecked={handleDefaultChecked(Settings.groupId.kraan)}
      ></input>
      <label className="cta-category" htmlFor={Settings.groupId.kraan}>
        <div>
          <img
            className="ddv-icon"
            src={Machine}
            alt="machines"
            style={{ scale: '0.8' }}
          ></img>
          Kranen
        </div>
      </label>

      <input
        type="radio"
        id={Settings.groupId.trailers}
        name="groupId"
        value={Settings.groupId.trailers}
        defaultChecked={handleDefaultChecked(Settings.groupId.trailers)}
      ></input>
      <label className="cta-category" htmlFor={Settings.groupId.trailers}>
        <div>
          <img className="ddv-icon" src={Trailer} alt="trailers"></img>
          Trailers
        </div>
      </label>
      <label className="cta-category" onClick={() => navigate('/')}>
        <div>
          <img className="ddv-icon" src={Luxewagen} alt="autos"></img>
          Voertuigen
        </div>
      </label>
    </div>
  );
};

export default Categories;
