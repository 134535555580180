import { Container } from "@mui/system";
import { Link } from "gatsby";
import Layout from "../components/layout";

const NotFoundPage = () => (
    <Layout>
        <Container>
            <h1>Deze pagina is niet gevonden.</h1>
            <Link to='/' className="button primary-button">Keer terug naar de homepage</Link>
        </Container>
    </Layout>
);

export default NotFoundPage;
