import { Container } from "@mui/system"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import SEO from "../components/seo"

const renderFullStars = (fullStars) => {
    return fullStars !== 0
        ? Array(fullStars)
            .fill(null)
            .map((item, i) => {
                return <span className="star" key={`fs${i}`}>&#11088;</span>
            })
        : ''
}

export const query = graphql`
  {
    strapiReview {
      title
      seoBasic {
        seoTitle
        seoMetadescription
      }
      reviews {
        strapi_id
        rating
        plaats
        naam
        body
      }
    }
  }
`


const Getuigenissen = ({data}) => {
    const reviewData = data.strapiReview
    return (
        <Layout>
        <SEO title={reviewData.seoBasic.seoTitle} description={reviewData.seoBasic.seoMetadescription}></SEO>
            <Container>
                <h1>{reviewData.title}</h1>
                <div className="reviews-wrapper">
                    {reviewData.reviews.map((review) => (
                        <div className="review" key={review.strapi_id}>
                            <div className="review__header">
                                <div className="review__name">
                                    {review.naam}
                                </div>
                                <div className="review__city">
                                    {
                                        review.plaats
                                            ? `- ${review.plaats}`
                                            : ''
                                    }
                                </div>
                            </div>
                            <div className="review__stars">
                                {renderFullStars(review.rating)}
                            </div>
                            <div className="review__content">
                                <p>
                                    {review.body}
                                </p>
                            </div>
                        </div>
                    ))}
                </div>
            </Container>
        </Layout>
    )
}

export default Getuigenissen;