import { createSlice } from '@reduxjs/toolkit';

// workaround for browser-only feature gatsby
// eslint-disable-next-line @typescript-eslint/no-explicit-any

// let localstoreCustomer;

// if (typeof window !== 'undefined') {
//   localstoreCustomer = JSON.parse(localStorage.getItem('customerReservation') || '{}');
// }

const initialState = {
  loading: false,
  customer: {},
  lastName: null,
  reservationReference: null,
  error: null,
  token: null,
  openDialog: false,
  clientSecret: null,
  success: false, // for monitoring the registration process.
};

const customerSlice = createSlice({
  name: 'customer',
  initialState,
  reducers: {
    setCustomer: (state, action) => {
      state.customer = action.payload.customer;
      state.reservationReference = action.payload.reservationReference;
      state.lastName = action.payload.lastName;
    },
    setToken: (state, action) => {
      state.token = action.payload;
    },
    setClientSecret: (state, action) => {
      state.openDialog = true;
      state.clientSecret = action.payload;
    },
    removeClientSecret: (state) => {
      state.openDialog = false;
      state.clientSecret = null;
    },
  },
});

export const { setCustomer, setToken, setClientSecret, removeClientSecret } =
  customerSlice.actions;
export default customerSlice.reducer;
