import Layout from '../components/layout';
import SEO from '../components/seo';
import { Container } from '@mui/system';
import ReactMarkdown from 'react-markdown';
import ContactCta from '../components/ContactCta';

const Static = (props) => {
  const { title, rows, seoBasic, images } = props.pageContext;

  return (
    <Layout>
      <SEO
        title={seoBasic.seoTitle}
        description={seoBasic.seoMetadescription}
        index={true}
        follow={true}
      ></SEO>
      <section>
        <Container>
          <h1>{title}</h1>

          {rows.map((row) => (
            <div className="row-50" key={row.strapi_id}>
              <div>
                <ReactMarkdown>{row.body.data.body}</ReactMarkdown>
              </div>
              {row.image && (
                <div>
                  <img
                    src={row.image.url}
                    alt={row.image.alternativeText}
                    title={row.image.caption}
                  />
                </div>
              )}
            </div>
          ))}
        </Container>
      </section>
      {/* TODO: Robust implementation of imageWithLink Section */}
      {title === 'Lange termijn huren' && (
        <section className="bg-medium-gray">
          <ContactCta />
        </section>
      )}
      {/* TODO: Images section */}
      {title === 'Over Ons' && (
        <section className="bg-medium-gray">
          <div className="grid-25">
            {images.map((image) => (
              <div>
                <img
                  src={image.url}
                  alt={image.alternativeText}
                  title={image.caption}
                  style={{ maxWidth: '100%' }}
                />
              </div>
            ))}
          </div>
        </section>
      )}
    </Layout>
  );
};

export default Static;
