import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { API_URL, channelId, clientId } from '../../constants/api';
import { useSelector } from 'react-redux';
import { saveAs } from 'file-saver';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

const formatName = (name) => {
  switch (name) {
    case 'RentalAgreement':
      return (name = 'Huur Overeenkomst');
    case 'TaxInvoice':
      return (name = 'Factuur');
    case 'VehiclePickupReceipt':
      return (name = 'Uitgifte Formulier');
    case 'VehicleDropOffReceipt':
      return (name = 'Inname Formulier');
    default:
      return name;
  }
};

const CustomerDocuments = () => {
  const { reservationReference } = useSelector((state) => state.customer);

  const [error, setError] = useState('');
  const [documents, setDocuments] = useState();

  useEffect(() => {
    const getDocuments = async () => {
      try {
        const docs = await axios.get(
          `${API_URL}/customers/reservations/${reservationReference}/documents`,
          {
            headers: {
              clientId,
              channelId,
            },
          }
        );

        setDocuments(docs.data);
      } catch (e) {
        setError('Ophalen documenten niet gelukt.');
      }
    };

    getDocuments();
  }, []);

  const getDocument = async (id, fileName, mimeType) => {
    try {
      const doc = await axios.get(
        `${API_URL}/customers/reservations/${reservationReference}/documents/${id}`,
        {
          headers: {
            clientId,
            channelId,
          },
          responseType: 'arraybuffer',
        }
      );

      // const link = document.createElement('a');
      //   link.href = "data:application/octet-stream," + doc.data;
      //   link.download = 'file.pdf';
      //   link.click();

      const blob = new Blob([doc.data], { type: mimeType });
      saveAs(blob, fileName || 'document.pdf');
    } catch (e) {
      setError('Ophalen document niet gelukt.');
    }
  };

  return (
    <div className="documents-wrapper">
      <h2>Documenten</h2>
      {documents && (
        <div className="customer-documents">
          {documents
            .filter((doc) => doc.documentType !== 'ProFormaInvoice')
            .map((doc) => (
              <div
                key={doc.documentId}
                className="customer-document-wrapper"
                onClick={() =>
                  getDocument(doc.documentId, doc.fileName, doc.mimeType)
                }
              >
                <h3>{formatName(doc.documentType)}</h3>
                <PictureAsPdfIcon fontSize="large" />
                <h4>{doc.fileName}</h4>
              </div>
            ))}
        </div>
      )}

      {!documents && !error && <p>Documenten worden opgehaald.</p>}
    </div>
  );
};

export default CustomerDocuments;
